import { Box, SxProps, Theme, Typography } from '@mui/material';
import { ActionButton, ActionButtonProps } from './ActionButton';

export interface HeaderProps {
  icon: React.ReactNode;
  title: string;
  actions?: ActionButtonProps[];
  sx?: SxProps<Theme>;
}

export const Header = ({ icon, title, actions = [], sx = {} }: HeaderProps) => {
  return (
    <Box
      sx={[
        {
          display: 'flex',
          overflow: 'hidden',
          flexDirection: 'row',
          gap: 1,
          padding: 1,
          alignItems: 'center',
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <Box
        p={1}
        paddingRight={0}
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {icon}
      </Box>
      <Typography
        sx={{
          fontSize: '1.125rem',
        }}
      >
        {title}
      </Typography>
      {actions && actions.length > 0 && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            marginLeft: 'auto',
          }}
        >
          {actions.map((actionButtonProps, index) => (
            <ActionButton key={index} {...actionButtonProps} />
          ))}
        </Box>
      )}
    </Box>
  );
};
