import { ArticleParagraph } from '@xyla/article';
import { getCalculatorFunction } from './registry';
import { CalculatorOutput, CalculatorValues } from './types';
import { Interpreter } from './Interpreter';

function createEmptyParagraph(): ArticleParagraph {
  return {
    articlespan_set: [],
    identifier: '',
    link_name: '',
    order_in_section: 0,
    paragraph_title: '',
  };
}

/** Run the actual calculation to get a set of scores */
export function calculateScores(
  slug: string,
  values: CalculatorValues
): CalculatorOutput {
  const calculatorFunction = getCalculatorFunction(slug);
  return calculatorFunction?.(values) ?? null;
}

/** Given calculator scores, constructs an interpretation as a list of ArticleParagraphs. */
export function interpretScores(
  scores: CalculatorOutput,
  interpreter?: Interpreter
): ArticleParagraph[] {
  if (!scores || !interpreter) {
    return [];
  }

  const interpretationParagraphs: ArticleParagraph[] = [];
  for (let [key, score] of Object.entries(scores)) {
    // KEEP IN SYNC WITH clinical_calculator.py
    // "Computed value for" is used in BE serialization (which just serializes the paragraphs normally)
    // to help the writing figure out what is going on.
    let paragraphTitle = '';
    if (score.prefix) {
      paragraphTitle = `Computed value for ${score.prefix}: ${score.value} ${
        score.suffix ?? ''
      }`;
    } else {
      paragraphTitle = `Computed calculator value: ${score.value} ${
        score.suffix ?? ''
      }`;
    }
    paragraphTitle = paragraphTitle.trim();

    // Get the interpretations for this score
    const interpretationObj =
      typeof score.value === 'string'
        ? null
        : interpreter.interpret(score.value, key);

    const interpretationSpans = interpretationObj?.interpretation_spans ?? null;

    // Create the paragraph
    interpretationParagraphs.push({
      ...createEmptyParagraph(),
      paragraph_title: paragraphTitle,
      articlespan_set: interpretationSpans ?? [],
      identifier: 'interpretation',
    });
  }

  return interpretationParagraphs;
}
