function round(num: number, fractionDigits: number): number {
  return Number(num.toFixed(fractionDigits));
}

export const universalUnitConverter = (
  value: number,
  baseUnit: string,
  targetUnit: string,
  labelName?: string
): number => {
  if (baseUnit === targetUnit) {
    return value;
  }

  if (baseUnit === 'lbs' && targetUnit === 'kg') {
    return round(value * 0.453592, 2);
  } else if (baseUnit === 'kg' && targetUnit === 'lbs') {
    return round(value * 2.20462, 2);
  } else if (baseUnit === 'in' && targetUnit === 'cm') {
    return round(value * 2.54, 2);
  } else if (baseUnit === 'cm' && targetUnit === 'in') {
    return round(value * 0.393701, 2);
  } else {
    // Raise error if baseUnit or targetUnit is not in the units list
    throw new Error('Conversion not implemented');
  }
};
