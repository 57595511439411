// DO NOT MODIFY THIS FILE. IT WAS GENERATED BY GPT-4. ANY CHANGES WILL BE OVERWRITTEN.
import { registerCalculator } from '../registry';
import { CalculatorOutputValue, CalculatorOutput } from '../types';

export const WellsCriteriaPECalculator = (values: {
  clinical_signs_DVT?: boolean;
  PE_most_likely?: boolean;
  heart_rate?: boolean;
  immobilization_or_surgery?: boolean;
  previous_DVT_or_PE?: boolean;
  hemoptysis?: boolean;
  malignancy?: boolean;
}): CalculatorOutput => {
  const {
    clinical_signs_DVT = false,
    PE_most_likely = false,
    heart_rate = false,
    immobilization_or_surgery = false,
    previous_DVT_or_PE = false,
    hemoptysis = false,
    malignancy = false,
  } = values;

  if (
    clinical_signs_DVT === null ||
    clinical_signs_DVT === undefined ||
    PE_most_likely === null ||
    PE_most_likely === undefined ||
    heart_rate === null ||
    heart_rate === undefined ||
    immobilization_or_surgery === null ||
    immobilization_or_surgery === undefined ||
    previous_DVT_or_PE === null ||
    previous_DVT_or_PE === undefined ||
    hemoptysis === null ||
    hemoptysis === undefined ||
    malignancy === null ||
    malignancy === undefined
  ) {
    return null;
  }

  let score = 0;
  if (clinical_signs_DVT) {
    score += 3;
  }
  if (PE_most_likely) {
    score += 3;
  }
  if (heart_rate) {
    score += 1.5;
  }
  if (immobilization_or_surgery) {
    score += 1.5;
  }
  if (previous_DVT_or_PE) {
    score += 1.5;
  }
  if (hemoptysis) {
    score += 1;
  }
  if (malignancy) {
    score += 1;
  }

  return {
    wells_pe: {
      prefix: "Wells' Criteria for Pulmonary Embolism",
      value: score,
    },
  };
};

registerCalculator(
  'wells-criteria-pe',
  WellsCriteriaPECalculator,
  "Wells' Criteria for Pulmonary Embolism"
);
