// DO NOT MODIFY THIS FILE. IT WAS GENERATED BY GPT-4. ANY CHANGES WILL BE OVERWRITTEN.
import { registerCalculator } from '../registry';
import { CalculatorOutputValue, CalculatorOutput } from '../types';

export const ARISCATCalculator = (values: {
  age?: number | null;
  preoperative_spO2?: number | null;
  preoperative_hemoglobin?: boolean | null;
  recent_respiratory_infection?: boolean | null;
  surgical_incision?: number | null;
  duration_surgery?: number | null;
  emergency_surgery?: boolean | null;
}): CalculatorOutput => {
  const {
    age,
    preoperative_spO2,
    preoperative_hemoglobin,
    recent_respiratory_infection,
    surgical_incision,
    duration_surgery,
    emergency_surgery,
  } = values;

  if (
    age === null ||
    age === undefined ||
    preoperative_spO2 === null ||
    preoperative_spO2 === undefined ||
    preoperative_hemoglobin === null ||
    preoperative_hemoglobin === undefined ||
    recent_respiratory_infection === null ||
    recent_respiratory_infection === undefined ||
    surgical_incision === null ||
    surgical_incision === undefined ||
    duration_surgery === null ||
    duration_surgery === undefined ||
    emergency_surgery === null ||
    emergency_surgery === undefined
  ) {
    return null;
  }

  let score = 0;

  if (age === 1) {
    score += 3;
  } else if (age === 2) {
    score += 16;
  }

  if (preoperative_spO2 === 1) {
    score += 8;
  } else if (preoperative_spO2 === 2) {
    score += 24;
  }

  score += preoperative_hemoglobin ? 11 : 0;
  score += recent_respiratory_infection ? 17 : 0;

  if (surgical_incision === 1) {
    score += 15;
  } else if (surgical_incision === 2) {
    score += 24;
  }

  if (duration_surgery === 1) {
    score += 16;
  } else if (duration_surgery === 2) {
    score += 23;
  }

  score += emergency_surgery ? 8 : 0;

  return {
    ariscat: {
      prefix: 'ARISCAT Score',
      value: score,
      suffix: '',
    },
  };
};

registerCalculator('ariscat', ARISCATCalculator, 'ARISCAT Score');
