// DO NOT MODIFY THIS FILE. IT WAS GENERATED BY GPT-4. ANY CHANGES WILL BE OVERWRITTEN.
import { registerCalculator } from '../registry';
import { CalculatorOutputValue, CalculatorOutput } from '../types';

export const RCRICalculator = (values: {
  high_risk_surgery?: boolean;
  history_of_ischemic_heart_disease?: boolean;
  history_of_congestive_heart_failure?: boolean;
  history_of_cerebrovascular_disease?: boolean;
  preoperative_insulin_treatment?: boolean;
  preoperative_serum_creatinine?: boolean;
}): CalculatorOutput => {
  const {
    high_risk_surgery = false,
    history_of_ischemic_heart_disease = false,
    history_of_congestive_heart_failure = false,
    history_of_cerebrovascular_disease = false,
    preoperative_insulin_treatment = false,
    preoperative_serum_creatinine = false,
  } = values;

  if (
    high_risk_surgery === null ||
    high_risk_surgery === undefined ||
    history_of_ischemic_heart_disease === null ||
    history_of_ischemic_heart_disease === undefined ||
    history_of_congestive_heart_failure === null ||
    history_of_congestive_heart_failure === undefined ||
    history_of_cerebrovascular_disease === null ||
    history_of_cerebrovascular_disease === undefined ||
    preoperative_insulin_treatment === null ||
    preoperative_insulin_treatment === undefined ||
    preoperative_serum_creatinine === null ||
    preoperative_serum_creatinine === undefined
  ) {
    return null;
  }

  let rcri_score = 0;
  if (high_risk_surgery) {
    rcri_score += 1;
  }
  if (history_of_ischemic_heart_disease) {
    rcri_score += 1;
  }
  if (history_of_congestive_heart_failure) {
    rcri_score += 1;
  }
  if (history_of_cerebrovascular_disease) {
    rcri_score += 1;
  }
  if (preoperative_insulin_treatment) {
    rcri_score += 1;
  }
  if (preoperative_serum_creatinine) {
    rcri_score += 1;
  }

  return {
    rcri: {
      prefix: 'RCRI Score',
      value: rcri_score,
    },
  };
};

registerCalculator('rcri', RCRICalculator, 'Revised Cardiac Risk Index (RCRI)');
