// DO NOT MODIFY THIS FILE. IT WAS GENERATED BY GPT-4. ANY CHANGES WILL BE OVERWRITTEN.
import { registerCalculator } from '../registry';
import { CalculatorOutputValue, CalculatorOutput } from '../types';

export const GlascowBlatchfordBleedingScoreCalculator = (values: {
  sex?: 'male' | 'female' | null;
  hemoglobin?: number | null;
  bun?: number | null;
  initial_sbp?: number | null;
  heart_rate?: boolean | null;
  melena?: boolean | null;
  syncope?: boolean | null;
  hepatic_disease?: boolean | null;
  cardiac_failure?: boolean | null;
}): CalculatorOutput => {
  const {
    sex,
    hemoglobin,
    bun,
    initial_sbp,
    heart_rate = false,
    melena = false,
    syncope = false,
    hepatic_disease = false,
    cardiac_failure = false,
  } = values;

  if (
    sex === null ||
    sex === undefined ||
    hemoglobin === null ||
    hemoglobin === undefined ||
    bun === null ||
    bun === undefined ||
    initial_sbp === null ||
    initial_sbp === undefined ||
    heart_rate === null ||
    heart_rate === undefined ||
    melena === null ||
    melena === undefined ||
    syncope === null ||
    syncope === undefined ||
    hepatic_disease === null ||
    hepatic_disease === undefined ||
    cardiac_failure === null ||
    cardiac_failure === undefined
  ) {
    return null;
  }

  let score = 0;

  if (bun > 70) {
    score += 6;
  } else if (bun >= 28) {
    score += 4;
  } else if (bun >= 22.4) {
    score += 3;
  } else if (bun >= 18.2) {
    score += 2;
  }

  if (sex === 'male') {
    if (hemoglobin < 10) {
      score += 6;
    } else if (hemoglobin < 12) {
      score += 3;
    } else if (hemoglobin < 13) {
      score += 1;
    }
  }

  if (sex === 'female') {
    if (hemoglobin < 10) {
      score += 6;
    } else if (hemoglobin < 12) {
      score += 1;
    }
  }

  if (initial_sbp < 90) {
    score += 3;
  } else if (initial_sbp < 100) {
    score += 2;
  } else if (initial_sbp < 110) {
    score += 1;
  }

  score += heart_rate ? 1 : 0;
  score += melena ? 1 : 0;
  score += syncope ? 2 : 0;
  score += hepatic_disease ? 2 : 0;
  score += cardiac_failure ? 2 : 0;

  return {
    glasgow_blatchford: {
      prefix: 'Glasgow-Blatchford Bleeding Score',
      value: score,
      suffix: '',
    },
  };
};

registerCalculator(
  'glasgow-blatchford-bleeding-score',
  GlascowBlatchfordBleedingScoreCalculator,
  'Glasgow-Blatchford Bleeding Score'
);
