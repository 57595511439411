// DO NOT MODIFY THIS FILE. IT WAS GENERATED BY GPT-4. ANY CHANGES WILL BE OVERWRITTEN.
import { registerCalculator } from '../registry';
import { CalculatorOutputValue, CalculatorOutput } from '../types';

export const Curb65Calculator = (values: {
  confusion?: boolean;
  bun_urea?: boolean;
  resp_rate?: boolean;
  bp?: boolean;
  age?: boolean;
}): CalculatorOutput => {
  const { confusion, bun_urea, resp_rate, bp, age } = values;

  if (
    confusion === null ||
    confusion === undefined ||
    bun_urea === null ||
    bun_urea === undefined ||
    resp_rate === null ||
    resp_rate === undefined ||
    bp === null ||
    bp === undefined ||
    age === null ||
    age === undefined
  ) {
    return null;
  }

  const score = [confusion, bun_urea, resp_rate, bp, age].reduce(
    (acc, val) => acc + (val ? 1 : 0),
    0
  );

  return {
    curb_65: {
      prefix: 'CURB-65',
      value: score,
    },
  };
};

registerCalculator('curb65', Curb65Calculator, 'CURB-65');
