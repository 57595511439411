// DO NOT MODIFY THIS FILE. IT WAS GENERATED BY GPT-4. ANY CHANGES WILL BE OVERWRITTEN.
import { registerCalculator } from '../registry';
import { CalculatorOutputValue, CalculatorOutput } from '../types';

export const MeanArterialPressureMapCalculator = (values: {
  systolic?: number | null;
  diastolic?: number | null;
}): CalculatorOutput => {
  const { systolic, diastolic } = values;

  if (systolic == null || diastolic == null) {
    return null;
  }

  const mapValue = (2 * diastolic + systolic) / 3;
  const roundedMapValue = Math.round(mapValue * 100) / 100;

  return {
    map: {
      prefix: 'Mean Arterial Pressure',
      value: roundedMapValue,
      suffix: 'mmHg',
    },
  };
};

registerCalculator(
  'mean-arterial-pressure',
  MeanArterialPressureMapCalculator,
  'Mean Arterial Pressure'
);
