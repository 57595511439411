// calculatorRegistry.ts
import { CalculatorFunction, CalculatorEntry } from './types';
export const calculatorRegistry: { [key: string]: CalculatorEntry } = {};

export const registerCalculator = (
  name: string,
  func: CalculatorFunction,
  displayName: string
) => {
  calculatorRegistry[name] = { func, displayName };
};

export const getCalculatorFunction = (
  name: string
): CalculatorFunction | undefined => {
  return calculatorRegistry[name].func;
};
