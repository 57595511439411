// DO NOT MODIFY THIS FILE. IT WAS GENERATED BY GPT-4. ANY CHANGES WILL BE OVERWRITTEN.
import { registerCalculator } from '../registry';
import { CalculatorOutputValue, CalculatorOutput } from '../types';

export const GAD7Calculator = (values: {
  feeling_nervous?: number;
  not_being_able_to_stop_worrying?: number;
  worrying_too_much?: number;
  trouble_relaxing?: number;
  being_so_restless?: number;
  becoming_easily_annoyed?: number;
  feeling_afraid?: number;
}): CalculatorOutput => {
  const {
    feeling_nervous = 0,
    not_being_able_to_stop_worrying = 0,
    worrying_too_much = 0,
    trouble_relaxing = 0,
    being_so_restless = 0,
    becoming_easily_annoyed = 0,
    feeling_afraid = 0,
  } = values;

  if (
    feeling_nervous === null ||
    feeling_nervous === undefined ||
    not_being_able_to_stop_worrying === null ||
    not_being_able_to_stop_worrying === undefined ||
    worrying_too_much === null ||
    worrying_too_much === undefined ||
    trouble_relaxing === null ||
    trouble_relaxing === undefined ||
    being_so_restless === null ||
    being_so_restless === undefined ||
    becoming_easily_annoyed === null ||
    becoming_easily_annoyed === undefined ||
    feeling_afraid === null ||
    feeling_afraid === undefined
  ) {
    return null;
  }

  const score =
    feeling_nervous +
    not_being_able_to_stop_worrying +
    worrying_too_much +
    trouble_relaxing +
    being_so_restless +
    becoming_easily_annoyed +
    feeling_afraid;

  return {
    gad_7: {
      prefix: 'GAD-7 Score',
      value: score,
    },
  };
};

registerCalculator('gad7', GAD7Calculator, 'GAD-7 Score');
