import { ArticleSpan } from '@xyla/article';

export interface DataInterpretation {
  interpretation: string[];
  interpretation_spans?: ArticleSpan[] | null;
  start?: number | null;
  end?: number | null;
  inclusive_start?: boolean | null;
  inclusive_end?: boolean | null;
  label_name?: string[];
}

export class Interpreter {
  dataInterpretation: DataInterpretation[];

  constructor(dataInterpretation: DataInterpretation[]) {
    this.dataInterpretation = dataInterpretation;
  }

  /** Returns an array of strings, which comprise the interpretation of a given score */
  interpret(score: number, label: string): DataInterpretation | null {
    for (const di of this.dataInterpretation) {
      if (di.label_name && !di.label_name.includes(label)) {
        continue;
      }

      const inclusiveStart = di.inclusive_start ?? true;
      const inclusiveEnd = di.inclusive_end ?? true;

      let startCondition = true;
      let endCondition = true;

      if (di.start !== undefined && di.start !== null) {
        startCondition = inclusiveStart ? score >= di.start : score > di.start;
      }

      if (di.end !== undefined && di.end !== null) {
        endCondition = inclusiveEnd ? score <= di.end : score < di.end;
      }

      if (startCondition && endCondition) {
        return di;
      }
    }

    return null;
  }
}
