import parse from 'html-react-parser';
import { Element as ScrollElement } from 'react-scroll';
import * as clipboard from 'clipboard-polyfill';

import { slugify } from '../utils';
import Bibliography from '../bibliography/Bibliography';
import AccordionView from './article_components/AccordionView';
import type { ArticleData, ArticleSection, Citation } from '../types';
import type { RenderFunctionFilter } from './spans';

import { renderFigure } from './figures';
import { RenderSpan } from './spans';
import { Box, Skeleton, SxProps } from '@mui/material';
import { Calculator, UnsupportedCalculatorMessage } from '@xyla/calculators';
import { DrugsSection } from './article_components/DrugsSection';
import { GuidelinesSection } from './article_components/GuidelinesSection';
import { DocumentPreviewSection } from './article_components/DocumentPreviewSection';
import type { DraftFunction } from 'use-immer';

/** If a section identifier is included here, we should proceed with the rendering pipeline even if the paragraph set is empty. */
const SHOULD_RENDER_SECTION_IDENTIFIER_IF_EMPTY = [
  'calculator',
  'calculator-unsupported',
];

// Same with paragraph idenfiers
const SHOULD_RENDER_PARAGRAPH_IDENTIFIER_IF_EMPTY = ['interpretation'];

export function processTextParagraphs(
  sectionData: ArticleSection,
  index: number,
  references: Citation[],
  notableStudyData: Record<string, unknown>,
  styles: Record<string, string>,
  displayDisagree: boolean,
  disableCitationLinks: boolean,
  additionalRenderFunctions: Record<string, RenderFunctionFilter>,
  additionalComponentData: Record<string, unknown>,
  firstTextSpanFound: { found: boolean },
  smallVersion: boolean,
  cursorSxFor: (_location: string) => SxProps,
  questionIndex?: number
) {
  const paragraphs_in_section: Record<number, JSX.Element> = {};

  // Get the normal text paragraphs
  for (let j = 0; j < sectionData.articleparagraph_set.length; j++) {
    let paragraph_data = sectionData.articleparagraph_set[j];
    let order_in_section = paragraph_data.order_in_section;
    // Handle the old version where there's no order_in_section
    if (paragraph_data.order_in_section === undefined) {
      order_in_section = j;
    }

    // If it's a special paragraph, handle separately
    let paragraph = null;
    if (paragraph_data.identifier === 'notable_studies') {
      paragraph = (
        <Bibliography {...notableStudyData} in_article_version={true} />
      );
    } else {
      // All spans in a paragraph
      let spans_in_paragraph = [];

      for (let k = 0; k < paragraph_data.articlespan_set.length; k++) {
        let articleSpan = paragraph_data.articlespan_set[k];
        let span_element = RenderSpan({
          articleSpan,
          references, // gets mutated!
          prefix: `${index}-${j}-${k}`,
          questionIndex,
          styles,
          displayDisagree,
          disableCitationLinks,
          additionalRenderFunctions,
          additionalComponentData,
          firstTextSpanFound,
          smallVersion,
          cursorSxFor: (location: string) =>
            cursorSxFor(
              `articleparagraph_set.${j}.articlespan_set.${k}.${location}`
            ),
        });
        if (span_element) {
          spans_in_paragraph.push(span_element);
        }
      }

      const paragraphTitleSx = cursorSxFor(
        `articleparagraph_set.${j}.paragraph_title`
      );

      const paragraphTitle =
        paragraph_data.identifier === 'interpretation'
          ? paragraph_data.paragraph_title
              .replace('Computed value for ', '')
              .replace('Computed calculator value: ', '')
          : paragraph_data.paragraph_title;

      const forceIncludeParagraph =
        SHOULD_RENDER_PARAGRAPH_IDENTIFIER_IF_EMPTY.includes(
          paragraph_data.identifier
        );

      // If the article is a quotation/snippet, handle things differently
      paragraph =
        spans_in_paragraph.length > 0 || forceIncludeParagraph ? (
          <div
            className={
              styles.paragraph_div + ' brandable--article-paragraph-title'
            }
            data-identifier={paragraph_data.identifier}
            key={`${index}-${order_in_section}`}
          >
            {paragraphTitle !== '' ? (
              <Box className={styles.paragraph_title} sx={paragraphTitleSx}>
                {paragraphTitle}
              </Box>
            ) : null}
            <div className={styles.paragraph_p}>{spans_in_paragraph}</div>
          </div>
        ) : null;
    }

    if (paragraph) {
      paragraphs_in_section[order_in_section] = paragraph;
    }
  }
  return paragraphs_in_section;
}

function processFigureParagraphs(
  sectionData: ArticleSection,
  index: number,
  references: Citation[],
  styles: Record<string, string>,
  displayDisagree: boolean,
  disableCitationLinks: boolean,
  additionalRenderFunctions: Record<string, RenderFunctionFilter>,
  additionalComponentData: Record<string, unknown>
) {
  const paragraphsInSection: Record<number, JSX.Element> = {};

  // Get the figure paragraphs
  if (sectionData.articlefigureparagraph_set !== undefined) {
    for (let j = 0; j < sectionData.articlefigureparagraph_set.length; j++) {
      let paragraph_data = sectionData.articlefigureparagraph_set[j];
      let order_in_section = paragraph_data.order_in_section;
      // Handle the old version where there's no order_in_section
      if (paragraph_data.order_in_section === undefined) {
        order_in_section = j;
      }

      // All figures in a figure paragraph
      let figures_in_paragraph = [];

      for (let k = 0; k < paragraph_data.articlefigure_set.length; k++) {
        let articleFigure = paragraph_data.articlefigure_set[k];
        let figure_element = renderFigure(
          articleFigure,
          references,
          index,
          j,
          k,
          styles,
          displayDisagree,
          disableCitationLinks,
          additionalRenderFunctions,
          additionalComponentData
        );
        if (figure_element != null) figures_in_paragraph.push(figure_element);
      }

      if (figures_in_paragraph.length === 0) {
        delete paragraphsInSection[order_in_section];
        continue;
      }

      const captionSpans =
        paragraph_data.captionspan_set
          ?.map((articleSpan, spanIndex) =>
            RenderSpan({
              articleSpan,
              references,
              prefix: `${index}-${j}-${1000000 + spanIndex}`,
              styles,
              displayDisagree,
              disableCitationLinks,
              additionalRenderFunctions,
              additionalComponentData,
            })
          )
          ?.filter(
            // FIXME
            /* eslint-disable-next-line no-loop-func */
            (spanElement): spanElement is JSX.Element => spanElement !== null
          ) ?? [];

      paragraphsInSection[order_in_section] = (
        <div className={styles.figure_paragraph_div}>
          {paragraph_data.paragraph_title === '' ? null : (
            <div
              className={
                styles.paragraph_title + ' brandable--article-paragraph-title'
              }
            >
              {paragraph_data.paragraph_title}
            </div>
          )}
          <div className={styles.figure_paragraph_inner_div}>
            {figures_in_paragraph}
          </div>
          {captionSpans.length > 0 ? (
            <div className={styles.figure_caption_p}>{captionSpans}</div>
          ) : null}
        </div>
      );
    }
  }
  return paragraphsInSection;
}

const SectionSkeleton = ({
  sectionClassName,
  sectionBodyClassName,
}: {
  sectionClassName: string;
  sectionBodyClassName: string;
}) => (
  <Box className={sectionClassName}>
    <Skeleton
      animation='wave'
      height={50}
      width='40%'
      style={{ marginBottom: 0, marginTop: 50 }}
    />
    <Box className={sectionBodyClassName}>
      {Array.from({ length: 8 }, (_, i) => (
        <Skeleton
          key={i}
          animation='wave'
          height={25}
          width='100%'
          style={{ marginBottom: 0 }}
        />
      ))}
      <Box>
        <Skeleton
          animation='wave'
          variant='rectangular'
          height={290}
          width='70%'
          style={{ margin: '20px auto 50px' }}
        />
      </Box>
    </Box>
  </Box>
);

interface DeriveGeneratedArticleProps {
  data: ArticleData;
  linesToSkip: number;
  notableStudyData: Record<string, unknown>;
  num_conditions: number;
  num_interventions: number;
  styles: Record<string, string>;
  displayDisagree: boolean;
  disableCitationLinks: boolean;
  additionalRenderFunctions: Record<string, RenderFunctionFilter>;
  additionalComponentData: Record<string, unknown>;
  smallVersion?: boolean;
  cursorSxFor?: (_location: string) => SxProps;
  questionIndex?: number;
  conversationLength?: number;
  /** Updater fn passed in from parent component, since we can't use a react hook here */
  setArticleData?: (updateFn: DraftFunction<ArticleData>) => void;
}

export default function deriveGeneratedArticle({
  data,
  linesToSkip,
  notableStudyData,
  num_conditions,
  num_interventions,
  styles,
  displayDisagree,
  disableCitationLinks,
  additionalRenderFunctions,
  additionalComponentData,
  smallVersion = false,
  cursorSxFor = () => ({}),
  questionIndex,
  conversationLength,
  setArticleData,
}: DeriveGeneratedArticleProps) {
  const references: Citation[] = [];
  const generatedArticle = [];
  const sectionTitles: [string, string, string][] = []; // type, slug, string
  let keyFindingsSection = null;
  const image_section = 2;

  const firstTextSpanFound = { found: false };

  for (let i = 1 + linesToSkip; i < data.articlesection_set.length; i++) {
    let section_data = data.articlesection_set[i];

    // Set the classnames, because it's needed for both the section content and the placeholder
    let section_style_name = '';
    if (num_interventions === 1 && num_conditions === 0) {
      section_style_name = styles.section_int;
    } else if (num_interventions === 1 && num_conditions === 1) {
      section_style_name = styles.section_ic;
    } else if (num_interventions === 0 && num_conditions === 1) {
      section_style_name = styles.section_cond;
    }

    let additionalClasses =
      data.articlesection_set[i].identifier === 'hide-on-print'
        ? ' hide-on-print'
        : '';

    let sectionClassName = `${styles.section} ${section_style_name} ${additionalClasses}`;
    let sectionBodyClassName = styles.section_body;

    // Short circuit if it's a placeholder section
    if (section_data.placeholder) {
      const placeholder = (
        <SectionSkeleton
          key={i}
          sectionClassName={sectionClassName}
          sectionBodyClassName={sectionBodyClassName}
        />
      );
      generatedArticle.push(placeholder);
      continue;
    }

    let paragraphs_in_section: Record<number, JSX.Element> = {};
    // let paragraph_link_names = {};

    Object.assign(
      paragraphs_in_section,
      processTextParagraphs(
        section_data,
        i,
        references, // gets mutated!
        notableStudyData,
        styles,
        displayDisagree,
        disableCitationLinks,
        additionalRenderFunctions,
        additionalComponentData,
        firstTextSpanFound,
        smallVersion,
        (location: string) =>
          cursorSxFor(`articlesection_set.${i}.${location}`),
        questionIndex
      )
    );

    // Even in the small version we want to show any figure paragraphs
    // NOTE: There can be figures in the "normal" text paragraphs too

    Object.assign(
      paragraphs_in_section,
      processFigureParagraphs(
        section_data,
        i,
        references,
        styles,
        displayDisagree,
        disableCitationLinks,
        additionalRenderFunctions,
        additionalComponentData
      )
    );

    // Order everything into a normal list
    const paragraphsInSectionList = Object.entries(paragraphs_in_section)
      .sort(([indexA], [indexB]) => +indexA - +indexB)
      .map(([_, node]) => node);

    // Skip out before writing the section if there are no paragraphs
    if (
      paragraphsInSectionList.length === 0 &&
      !SHOULD_RENDER_SECTION_IDENTIFIER_IF_EMPTY.includes(
        section_data.identifier
      )
    )
      continue;

    // Write the section element
    let section_title_element = null;
    if (data.articlesection_set[i].section_title !== '') {
      let section_title_string = data.articlesection_set[i].section_title;

      // Get clean version without any html/svgs etc
      const section_title_string_clean = section_title_string.includes('<')
        ? data.articlesection_set[i].section_title
            .substring(0, section_title_string.indexOf('<'))
            .trim()
        : data.articlesection_set[i].section_title.trim();

      const titleName = slugify(section_title_string_clean);
      const titleLinkNameBase = data.articlesection_set[i].identifier
        ? data.articlesection_set[i].identifier
        : titleName;
      const titleLinkName = titleLinkNameBase
        ?.toLowerCase()
        ?.replaceAll(' ', '');
      section_title_element = (
        <SectionTitle
          titleKey={titleName}
          title={section_title_string}
          name={titleLinkName}
          styles={styles}
          index={i}
          cursorSxFor={cursorSxFor}
        />
      );
      sectionTitles.push(['section', titleName, section_title_string_clean]);
    }

    let section_image_element = null;
    if (data.image_url && i === image_section) {
      section_image_element = (
        <div className={styles.image_wrapper}>
          <img src={data.image_url} alt='' />
        </div>
      );
    }

    const paragraphsToSectionBodyElement = (paragraphs: JSX.Element[]) => (
      <div className={sectionBodyClassName}>{paragraphs}</div>
    );

    const section_body_element = paragraphsToSectionBodyElement(
      paragraphsInSectionList
    );

    let sectionContent;
    if (section_data.identifier === 'guidelines') {
      sectionContent = (
        <GuidelinesSection
          section_title={section_data.section_title}
          {...section_data.metadata}
        >
          {section_body_element}
        </GuidelinesSection>
      );
    } else if (section_data.identifier === 'drugs') {
      sectionContent = (
        <DrugsSection
          section_title={section_data.section_title}
          {...section_data.metadata}
        >
          {section_body_element}
        </DrugsSection>
      );
    } else if (section_data.identifier === 'document-preview') {
      sectionContent = (
        <DocumentPreviewSection sectionData={section_data}>
          {section_body_element}
        </DocumentPreviewSection>
      );
    } else if (section_data.identifier === 'calculator') {
      sectionContent = (
        <>
          <Calculator
            setArticleData={setArticleData}
            sectionData={section_data}
            sectionIndex={i}
            paragraphsToSectionBodyElement={paragraphsToSectionBodyElement}
            // If the Calculator isn't in the most recent answer, disable it.
            disabled={
              questionIndex !== undefined &&
              conversationLength !== undefined &&
              questionIndex < conversationLength - 1
            }
          >
            {paragraphsInSectionList}
          </Calculator>
        </>
      );
    } else if (section_data.identifier === 'calculator-unsupported') {
      sectionContent = (
        <UnsupportedCalculatorMessage sectionData={section_data} />
      );
    } else {
      sectionContent = (
        <div>
          {section_title_element}
          {section_body_element}
          {section_image_element}
        </div>
      );
    }

    let section = (
      <div className={sectionClassName} key={`${i}-${i}`}>
        {section_data.identifier === 'accordion' ? (
          <AccordionView>{sectionContent}</AccordionView>
        ) : (
          sectionContent
        )}
      </div>
    );

    // If it's a special case key findings section, set the state explicitly
    if (data.articlesection_set[i].identifier === 'key_findings') {
      keyFindingsSection = section;
    } else {
      generatedArticle.push(section);
    }
  }

  return {
    keyFindingsSection,
    references,
    generatedArticle,
    sectionTitles,
  };
}

interface SectionTitleProps {
  titleKey: string;
  title: string;
  name: string;
  styles: Record<string, string>;
  index: number;
  cursorSxFor: (_location: string) => SxProps;
}

function SectionTitle({
  titleKey,
  title,
  name,
  styles,
  index,
  cursorSxFor,
}: SectionTitleProps) {
  const copyLinkToClipboard = () => {
    const link = window.location.href + '#' + name;
    clipboard.writeText(link);
  };

  const sectionTitleSx = cursorSxFor(
    `articlesection_set.${index}.section_title`
  );

  return (
    <ScrollElement name={name}>
      <Box
        className={
          styles.section_title +
          ' sectionTitle brandable--article-section-title'
        }
        key={titleKey}
        sx={sectionTitleSx}
      >
        {parse(title)}
        <a
          className='sectionTitle_link'
          href={'#' + name}
          onClick={copyLinkToClipboard}
        >
          <svg
            viewBox='0 0 16 16'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <g clipPath='url(#a)'>
              <path
                d='M1.093 9.616a3.746 3.746 0 0 0 0 5.29 3.746 3.746 0 0 0 5.29 0l2.94-2.938a3.746 3.746 0 0 0 0-5.29.623.623 0 1 0-.882.881 2.475 2.475 0 0 1 0 3.527L5.5 14.025a2.475 2.475 0 0 1-3.526 0 2.475 2.475 0 0 1 0-3.527l2.792-2.792a.624.624 0 1 0-.882-.882L1.093 9.616Zm5.584-5.584a3.746 3.746 0 0 0 0 5.29.623.623 0 1 0 .882-.881 2.475 2.475 0 0 1 0-3.527l2.94-2.939a2.475 2.475 0 0 1 3.526 0 2.475 2.475 0 0 1 0 3.527l-2.792 2.792a.623.623 0 1 0 .882.882l2.792-2.793a3.746 3.746 0 0 0 0-5.29 3.746 3.746 0 0 0-5.29 0l-2.94 2.94Z'
                fill='currentColor'
              />
            </g>
          </svg>
        </a>
      </Box>
    </ScrollElement>
  );
}
