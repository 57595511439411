// DO NOT MODIFY THIS FILE. IT WAS GENERATED BY GPT-4. ANY CHANGES WILL BE OVERWRITTEN.
import { registerCalculator } from '../registry';
import { CalculatorOutputValue, CalculatorOutput } from '../types';

export const MaintenanceFluidCalculator = (values: {
  weight?: number;
}): CalculatorOutput => {
  const weight = values.weight;

  if (weight === null || weight === undefined) {
    return null;
  }

  let fluid = 0;

  if (weight <= 10) {
    fluid = 4 * weight;
  } else if (weight <= 20) {
    fluid = 40 + 2 * (weight - 10);
  } else {
    fluid = 60 + (weight - 20);
  }

  return {
    maintenance_fluid: {
      prefix: 'Maintenance Fluid',
      value: fluid,
      suffix: 'mL/hr',
    },
  };
};

registerCalculator(
  'maintenance-fluid',
  MaintenanceFluidCalculator,
  'Maintenance Fluid Calculator'
);
