// DO NOT MODIFY THIS FILE. IT WAS GENERATED BY GPT-4. ANY CHANGES WILL BE OVERWRITTEN.
import { registerCalculator } from '../registry';
import { CalculatorOutputValue, CalculatorOutput } from '../types';

export const MaddreysDiscriminantFunctionCalculator = (values: {
  bilirubin?: number | null;
  prothrombin_time?: number | null;
  control_prothrombin_time?: number | null;
}): CalculatorOutput => {
  const { bilirubin, prothrombin_time, control_prothrombin_time } = values;

  if (
    bilirubin === null ||
    bilirubin === undefined ||
    prothrombin_time === null ||
    prothrombin_time === undefined ||
    control_prothrombin_time === null ||
    control_prothrombin_time === undefined
  ) {
    return null;
  }

  const score = 4.6 * (prothrombin_time - control_prothrombin_time) + bilirubin;
  const rounded_score = Math.round(score * 100) / 100;

  return {
    discriminant_function: { value: rounded_score },
  };
};

registerCalculator(
  'maddreys',
  MaddreysDiscriminantFunctionCalculator,
  "Maddrey's Discriminant Function"
);
