// DO NOT MODIFY THIS FILE. IT WAS GENERATED BY GPT-4. ANY CHANGES WILL BE OVERWRITTEN.
import { registerCalculator } from '../registry';
import { CalculatorOutputValue, CalculatorOutput } from '../types';

export const CapriniVenousThromboembolismCalculator = (values: {
  age?: number;
  height?: number;
  weight?: number;
  pregnancy_or_post_partum?: boolean;
  contraceptive_hormones?: boolean;
  history_of_unexplained_or_recurrent_spontaneous_abortion?: boolean;
  mobility?: number;
  history_ibd?: boolean;
  acute_mi?: boolean;
  copd?: boolean;
  present_or_previous_malignancy?: boolean;
  other_risk_factors?: boolean;
  surgery_type?: number;
  major_surgery?: boolean;
  chf?: boolean;
  sepsis?: boolean;
  pneumonia?: boolean;
  leg_movement?: boolean;
  hip_leg_pelvis_fracture?: boolean;
  stroke?: boolean;
  multiple_trauma?: boolean;
  spinal_cord_injury?: boolean;
  visible_varicose_veins?: boolean;
  swollen_leg?: boolean;
  central_venous_access?: boolean;
  history_dvt_pe?: boolean;
  family_history_thrombosis?: boolean;
  positive_factor_v_leiden?: boolean;
  positive_prothrombin_mutation?: boolean;
  positive_lupus_anticoagulant?: boolean;
  homocysteine?: boolean;
  anticardiolipin?: boolean;
  heparin_induced_thrombocytopenia?: boolean;
  other_thrombophilia?: boolean;
}): CalculatorOutput => {
  const {
    age,
    height,
    weight,
    pregnancy_or_post_partum = false,
    contraceptive_hormones = false,
    history_of_unexplained_or_recurrent_spontaneous_abortion = false,
    mobility = 0,
    history_ibd = false,
    acute_mi = false,
    copd = false,
    present_or_previous_malignancy = false,
    other_risk_factors = false,
    surgery_type = 0,
    major_surgery = false,
    chf = false,
    sepsis = false,
    pneumonia = false,
    leg_movement = false,
    hip_leg_pelvis_fracture = false,
    stroke = false,
    multiple_trauma = false,
    spinal_cord_injury = false,
    visible_varicose_veins = false,
    swollen_leg = false,
    central_venous_access = false,
    history_dvt_pe = false,
    family_history_thrombosis = false,
    positive_factor_v_leiden = false,
    positive_prothrombin_mutation = false,
    positive_lupus_anticoagulant = false,
    homocysteine = false,
    anticardiolipin = false,
    heparin_induced_thrombocytopenia = false,
    other_thrombophilia = false,
  } = values;

  if (
    age == null ||
    height == null ||
    weight == null ||
    pregnancy_or_post_partum == null ||
    contraceptive_hormones == null ||
    history_of_unexplained_or_recurrent_spontaneous_abortion == null ||
    mobility == null ||
    history_ibd == null ||
    acute_mi == null ||
    copd == null ||
    present_or_previous_malignancy == null ||
    other_risk_factors == null ||
    surgery_type == null ||
    major_surgery == null ||
    chf == null ||
    sepsis == null ||
    pneumonia == null ||
    leg_movement == null ||
    hip_leg_pelvis_fracture == null ||
    stroke == null ||
    multiple_trauma == null ||
    spinal_cord_injury == null ||
    visible_varicose_veins == null ||
    swollen_leg == null ||
    central_venous_access == null ||
    history_dvt_pe == null ||
    family_history_thrombosis == null ||
    positive_factor_v_leiden == null ||
    positive_prothrombin_mutation == null ||
    positive_lupus_anticoagulant == null ||
    homocysteine == null ||
    anticardiolipin == null ||
    heparin_induced_thrombocytopenia == null ||
    other_thrombophilia == null
  ) {
    return null;
  }

  let score = 0;
  score += age;

  const bmi = weight / (height / 100) ** 2;
  if (bmi >= 25) {
    score += 1;
  }

  score += pregnancy_or_post_partum ? 1 : 0;
  score += contraceptive_hormones ? 1 : 0;
  score += history_of_unexplained_or_recurrent_spontaneous_abortion ? 1 : 0;
  score += mobility;
  score += history_ibd ? 1 : 0;
  score += acute_mi ? 1 : 0;
  score += copd ? 1 : 0;
  score += present_or_previous_malignancy ? 2 : 0;
  score += other_risk_factors ? 1 : 0;

  score +=
    {
      0: 0,
      1: 1,
      2: 2,
      3: 5,
    }[surgery_type] || 0;
  score += major_surgery ? 1 : 0;
  score += chf ? 1 : 0;
  score += sepsis ? 1 : 0;
  score += pneumonia ? 1 : 0;
  score += leg_movement ? 2 : 0;
  score += hip_leg_pelvis_fracture ? 5 : 0;
  score += stroke ? 5 : 0;
  score += multiple_trauma ? 5 : 0;
  score += spinal_cord_injury ? 5 : 0;
  score += visible_varicose_veins ? 1 : 0;
  score += swollen_leg ? 1 : 0;
  score += central_venous_access ? 2 : 0;
  score += history_dvt_pe ? 3 : 0;
  score += family_history_thrombosis ? 3 : 0;
  score += positive_factor_v_leiden ? 3 : 0;
  score += positive_prothrombin_mutation ? 3 : 0;
  score += positive_lupus_anticoagulant ? 3 : 0;
  score += homocysteine ? 3 : 0;
  score += anticardiolipin ? 3 : 0;
  score += heparin_induced_thrombocytopenia ? 3 : 0;
  score += other_thrombophilia ? 3 : 0;

  return {
    caprini_score: {
      prefix: 'Caprini Score',
      value: score,
    },
  };
};

registerCalculator(
  'caprini-score-for-venous-thromboembolism',
  CapriniVenousThromboembolismCalculator,
  'Caprini Score for Venous Thromboembolism'
);
