import { Stack, Typography } from '@mui/material';

interface CalculatorRowLabelProps {
  label: string;
  description?: string;
}

/** Displays the label and optional description for one row in the calculator. */
export function CalculatorRowLabel({
  label,
  description,
}: CalculatorRowLabelProps) {
  return (
    <Stack spacing={0.5}>
      <Typography>{label}</Typography>
      {description && (
        <Typography variant='body2' color='text.secondary'>
          {description}
        </Typography>
      )}
    </Stack>
  );
}
