import { MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material';
import { Option } from './CalculatorButtonGroup';

interface CalculatorDropdownParameterProps {
  options: Option[];
  selectedValue: string | number | null;
  handleClick: (value: string | null) => void;
  display: 'vertical' | 'horizontal' | 'dropdown' | 'fully_horizontal';
  disabled?: boolean;
}

/** Displays a group of toggle buttons for a single calculator control */
export const CalculatorDropdownParameter: React.FC<
  CalculatorDropdownParameterProps
> = ({ options, selectedValue, handleClick, disabled }) => {
  const handleChange = (event: SelectChangeEvent) => {
    handleClick(event.target.value as string);
  };

  const processedValue =
    typeof selectedValue === 'string'
      ? selectedValue
      : typeof selectedValue === 'number'
      ? selectedValue.toString()
      : '';

  return (
    <Select
      value={processedValue}
      onChange={handleChange}
      sx={{ width: '100%' }}
      disabled={disabled}
    >
      {options.map((option) => (
        <MenuItem key={option.label} value={option.value}>
          <Typography variant='body2'>{option.label}</Typography>
        </MenuItem>
      ))}
    </Select>
  );
};
