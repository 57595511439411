import React, { useState, useEffect, useMemo, useRef } from 'react';
import {
  Box,
  Typography,
  TextField,
  Select,
  MenuItem,
  SelectChangeEvent,
  OutlinedInput,
  Stack,
} from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { universalUnitConverter } from './unitConverter';

export interface Unit {
  name: string;
  used_in_calculation: boolean;
  validators: Validator[];
}

export interface Validator {
  message: string;
  min?: number;
  max?: number;
}

export function validateInput(
  inputValue: number | null,
  validators: Validator[]
): string | null {
  if (inputValue === null || inputValue === undefined || isNaN(inputValue)) {
    return null;
  }

  for (const validator of validators) {
    if (validator.min !== undefined && inputValue < validator.min) {
      return validator.message;
    }
    if (validator.max !== undefined && inputValue > validator.max) {
      return validator.message;
    }
  }

  return null;
}

interface InputWithUnitsProps {
  label: string;
  value: number | null;
  onChange: (value: number | null) => void;
  units: Unit[];
  disabled?: boolean;
}

export const InputWithUnits: React.FC<InputWithUnitsProps> = ({
  label,
  value,
  onChange,
  units = [],
  disabled,
}) => {
  // Ref to track if the user is currently typing
  const isUserTyping = useRef(false);

  const calculationUnit = useMemo(() => {
    return units.find((unit) => unit.used_in_calculation) || units[0] || null;
  }, [units]);

  const [selectedUnit, setSelectedUnit] = useState<Unit | null>(
    units[0] || null
  );
  const [displayValue, setDisplayValue] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  useEffect(() => {
    if (!isUserTyping.current) {
      if (value !== null && calculationUnit && selectedUnit) {
        if (calculationUnit.name && selectedUnit.name) {
          var convertedValue = universalUnitConverter(
            value,
            calculationUnit.name,
            selectedUnit.name,
            label
          );
        } else {
          var convertedValue = value;
        }

        // do the validation
        const validators = selectedUnit?.validators || [];
        const error = validateInput(convertedValue, validators);
        if (
          convertedValue !== undefined &&
          convertedValue !== null &&
          !isNaN(convertedValue) &&
          !error
        ) {
          setErrorMessage(null);
          setDisplayValue(convertedValue.toString());
        } else if (error) {
          setErrorMessage(error);
          setDisplayValue(convertedValue.toString());
        } else {
          setDisplayValue('');
        }
      } else {
        setDisplayValue('');
        setErrorMessage('');
      }
    }
    // Reset the flag after updating displayValue
    isUserTyping.current = false;
  }, [value, selectedUnit, calculationUnit, label]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    setDisplayValue(inputValue);
    isUserTyping.current = true; // User is typing

    if (inputValue === '') {
      setErrorMessage(null);
      onChange(null);
    } else {
      const numValue = parseFloat(inputValue);
      if (!isNaN(numValue)) {
        // Validate the input before AND after converting
        const validatorsBefore = selectedUnit?.validators || [];
        const errorBefore = validateInput(numValue, validatorsBefore);

        if (errorBefore) {
          setErrorMessage(errorBefore);
          return;
        }

        let convertedValue = numValue;
        if (
          calculationUnit &&
          calculationUnit.name &&
          selectedUnit &&
          selectedUnit.name
        ) {
          convertedValue = universalUnitConverter(
            numValue,
            selectedUnit.name,
            calculationUnit.name,
            label
          );
        }

        const validators = calculationUnit?.validators || [];
        const error = validateInput(convertedValue, validators);
        if (!error) {
          setErrorMessage(null);
          if (convertedValue !== undefined && convertedValue !== null) {
            onChange(convertedValue);
          } else {
            setErrorMessage('Invalid unit conversion');
          }
        } else {
          setErrorMessage(error);
          // Do not call onChange when there's a validation error
        }
      } else {
        setErrorMessage('Invalid input');
        // Do not call onChange when input is not a valid number
      }
    }
  };

  const handleUnitChange = (event: SelectChangeEvent<string>) => {
    const newUnitName = event.target.value as string;
    const newUnit = units.find((unit) => unit.name === newUnitName);
    if (newUnit) {
      setSelectedUnit(newUnit);
    }
  };

  if (!units || units.length === 0 || !selectedUnit || !calculationUnit) {
    return <Typography color='error'>No valid units provided.</Typography>;
  }

  return (
    <>
      <Stack direction='row' justifyContent='space-between' gap={1}>
        <TextField
          value={displayValue}
          onChange={handleInputChange}
          variant='outlined'
          size='small'
          type='text' // Changed to 'text' to allow any input
          error={!!errorMessage}
          sx={{ flex: '2' }} // Flex values are rough
          InputProps={{
            inputProps: { style: { textAlign: 'right' } },
          }}
          disabled={disabled}
        />

        {units.length > 1 ? (
          <Select
            value={selectedUnit?.name || ''}
            onChange={handleUnitChange}
            variant='outlined'
            size='small'
            sx={{ flex: '1' }}
            disabled={disabled}
          >
            {units.map((unit) => (
              <MenuItem key={unit.name} value={unit.name}>
                {unit.name}
              </MenuItem>
            ))}
          </Select>
        ) : (
          <OutlinedInput
            value={selectedUnit?.name || ''}
            size='small'
            disabled
            sx={{ flex: '1' }}
            inputProps={{
              style: { textAlign: 'center' },
            }}
          />
        )}
      </Stack>

      {errorMessage && (
        <Box
          display='flex'
          alignItems='center'
          color='error.main'
          sx={{ marginTop: 0.5 }}
        >
          <ErrorOutlineIcon fontSize='small' sx={{ marginRight: 0.5 }} />
          <Typography variant='body2' color='error'>
            {errorMessage}
          </Typography>
        </Box>
      )}
    </>
  );
};
