// DO NOT MODIFY THIS FILE. IT WAS GENERATED BY GPT-4. ANY CHANGES WILL BE OVERWRITTEN.
import { registerCalculator } from '../registry';
import { CalculatorOutputValue, CalculatorOutput } from '../types';

export const CowsCalculator = (values: {
  resting_pulse_rate?: '<80' | '80-99' | '100-119' | '>120';
  sweating?: number;
  restlessness?: number;
  pupil_size?: number;
  bone_or_joint_aches?: number;
  runny_nose_or_tearing?: number;
  gi_upset?: number;
  tremor?: number;
  yawning?: number;
  anxiety_or_irritability?: number;
  gooseflesh_skin?: number;
}): CalculatorOutput => {
  const {
    resting_pulse_rate = '<80',
    sweating = 0,
    restlessness = 0,
    pupil_size = 0,
    bone_or_joint_aches = 0,
    runny_nose_or_tearing = 0,
    gi_upset = 0,
    tremor = 0,
    yawning = 0,
    anxiety_or_irritability = 0,
    gooseflesh_skin = 0,
  } = values;

  if (
    resting_pulse_rate === null ||
    resting_pulse_rate === undefined ||
    sweating === null ||
    sweating === undefined ||
    restlessness === null ||
    restlessness === undefined ||
    pupil_size === null ||
    pupil_size === undefined ||
    bone_or_joint_aches === null ||
    bone_or_joint_aches === undefined ||
    runny_nose_or_tearing === null ||
    runny_nose_or_tearing === undefined ||
    gi_upset === null ||
    gi_upset === undefined ||
    tremor === null ||
    tremor === undefined ||
    yawning === null ||
    yawning === undefined ||
    anxiety_or_irritability === null ||
    anxiety_or_irritability === undefined ||
    gooseflesh_skin === null ||
    gooseflesh_skin === undefined
  ) {
    return null;
  }

  const points_for_resting_pulse_rate: { [key: string]: number } = {
    '<80': 0,
    '80-99': 1,
    '100-119': 2,
    '>120': 4,
  };

  const points_for_restlessness = [0, 1, 3, 5];
  const points_pupil_size = [0, 1, 2, 5];
  const points_for_bone_or_joint_aches = [0, 1, 2, 4];
  const points_for_runny_nose_or_tearing = [0, 1, 2, 4];
  const points_for_gi_upset = [0, 1, 2, 3, 5];
  const points_for_tremor = [0, 1, 2, 4];
  const points_for_yawning = [0, 1, 2, 4];
  const points_for_anxiety_or_irritability = [0, 1, 2, 4];
  const points_for_gooseflesh_skin = [0, 3, 5];

  const score =
    points_for_resting_pulse_rate[resting_pulse_rate] +
    sweating +
    points_for_restlessness[restlessness] +
    points_pupil_size[pupil_size] +
    points_for_bone_or_joint_aches[bone_or_joint_aches] +
    points_for_runny_nose_or_tearing[runny_nose_or_tearing] +
    points_for_gi_upset[gi_upset] +
    points_for_tremor[tremor] +
    points_for_yawning[yawning] +
    points_for_anxiety_or_irritability[anxiety_or_irritability] +
    points_for_gooseflesh_skin[gooseflesh_skin];

  return {
    cows_score: {
      prefix: 'COWS Score',
      value: score,
      suffix: '',
    },
  };
};

registerCalculator(
  'cows',
  CowsCalculator,
  'COWS (Clinical Opiate Withdrawal Scale)'
);
