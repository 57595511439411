// WhenToUse.tsx

import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { ReactNode } from 'react';

interface WhenToUseProps {
  whenToUseSectionElement: ReactNode | null;
}

export const WhenToUse: React.FC<WhenToUseProps> = ({
  whenToUseSectionElement,
}) => {
  if (whenToUseSectionElement === null) {
    return null;
  }

  return (
    <Accordion
      disableGutters
      sx={{
        // TODO(andy): These are overridden in openevidence/src/global.css. Get rid of those.
        // Need to consolidate with the ArticleReferences accordion.
        border: 'none !important',
        borderTop: (theme) => `1px solid ${theme.palette.divider} !important`,
        borderBottom: (theme) =>
          `1px solid ${theme.palette.divider} !important`,
        borderRadius: '0 !important',
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        sx={{
          px: 0,
          py: 0.5,
          '& .MuiAccordionSummary-content': { alignItems: 'center', gap: 1 },
        }}
      >
        <AccessTimeIcon fontSize='small' sx={{ color: 'text.secondary' }} />
        <Typography fontWeight={500} fontSize='1.125rem'>
          When to Use
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ pt: 0, px: 0 }}>
        {whenToUseSectionElement}
      </AccordionDetails>
    </Accordion>
  );
};
