// DO NOT MODIFY THIS FILE. IT WAS GENERATED BY GPT-4. ANY CHANGES WILL BE OVERWRITTEN.
import { registerCalculator } from '../registry';
import { CalculatorOutputValue, CalculatorOutput } from '../types';

export const WellsCriteriaDVTCalculator = (values: {
  active_cancer?: boolean;
  paralysis_or_recent_immobilization?: boolean;
  recently_bedridden?: boolean;
  localized_tenderness?: boolean;
  entire_leg_swollen?: boolean;
  calf_swelling?: boolean;
  pitting_edema?: boolean;
  collateral_superficial_veins?: boolean;
  previous_DVT?: boolean;
  alternative_diagnosis?: boolean;
}): CalculatorOutput => {
  const {
    active_cancer = false,
    paralysis_or_recent_immobilization = false,
    recently_bedridden = false,
    localized_tenderness = false,
    entire_leg_swollen = false,
    calf_swelling = false,
    pitting_edema = false,
    collateral_superficial_veins = false,
    previous_DVT = false,
    alternative_diagnosis = false,
  } = values;

  if (
    active_cancer === null ||
    active_cancer === undefined ||
    paralysis_or_recent_immobilization === null ||
    paralysis_or_recent_immobilization === undefined ||
    recently_bedridden === null ||
    recently_bedridden === undefined ||
    localized_tenderness === null ||
    localized_tenderness === undefined ||
    entire_leg_swollen === null ||
    entire_leg_swollen === undefined ||
    calf_swelling === null ||
    calf_swelling === undefined ||
    pitting_edema === null ||
    pitting_edema === undefined ||
    collateral_superficial_veins === null ||
    collateral_superficial_veins === undefined ||
    previous_DVT === null ||
    previous_DVT === undefined ||
    alternative_diagnosis === null ||
    alternative_diagnosis === undefined
  ) {
    return null;
  }

  let score = 0;
  if (active_cancer) score += 1;
  if (paralysis_or_recent_immobilization) score += 1;
  if (recently_bedridden) score += 1;
  if (localized_tenderness) score += 1;
  if (entire_leg_swollen) score += 1;
  if (calf_swelling) score += 1;
  if (pitting_edema) score += 1;
  if (collateral_superficial_veins) score += 1;
  if (previous_DVT) score += 1;
  if (alternative_diagnosis) score -= 2;

  return {
    wells_criteria_dvt: {
      prefix: "Wells' DVT Criteria Score",
      value: score,
    },
  };
};

registerCalculator(
  'wells-criteria-dvt',
  WellsCriteriaDVTCalculator,
  "Wells' Criteria for DVT"
);
