import { AnswerEngagedSubeventProps, MixpanelEventName } from '@xyla/analytics';
import { ComponentType, ReactNode, createContext, useContext } from 'react';
import type { PlotParams } from 'react-plotly.js';
import type { ArticleData, CitationDetail } from './types';
import { Step } from './ArticlePlaceholder';
import type { DraftFunction } from 'use-immer';

const LinkContext = createContext<ComponentType<any> | null>(null);
const LinkProvider = LinkContext.Provider;

const useLinkContext = () => {
  const Link = useContext(LinkContext);
  if (Link === null) {
    throw new Error('Tried to use Link component with no LinkContext');
  }
  return Link;
};

const PlotlyContext = createContext<ComponentType<PlotParams> | null>(null);
const PlotlyProvider = PlotlyContext.Provider;

const usePlotlyContext = () => {
  const Plotly = useContext(PlotlyContext);
  if (Plotly === null) {
    throw new Error('Tried to use Plotly component with no PlotlyContext');
  }
  return Plotly;
};

interface ArticleContextType {
  customLoadingSpinner?: ReactNode;
  feedbackSuccessText?: string;
  transformReferenceLink?: (detail: CitationDetail) => string;

  /**
   * Sends a tracking request to Mixpanel, but relies on the parent OutputDisplay
   * component to populate article properties. Eliminates prop drilling.
   */
  trackArticleEvent?: (
    eventName: MixpanelEventName,
    eventProps: AnswerEngagedSubeventProps
  ) => void;

  /**
   * Records feedback to hotjar, but relies on the parent OutputDisplay
   * component to populate article properties. Eliminates prop drilling to article references.
   */
  recordReferenceFeedback?: (
    isPositive: boolean,
    feedback: string,
    categories: string[],
    referenceIndex: number
  ) => Promise<void>;

  /**
   * Used to set the id of the container in AskPageLayout, which wraps the
   * AskWrapper component. This is eventually passed into the CtrlLink component
   * to enable scrolling within the container when citation links are clicked.
   */
  askWrapperContainerId?: string;

  /** Should we hide the green checkmark at the top of the agent plan banner? */
  agentHideIcon?: boolean;

  /** What should the text say at the top of the agent plan banner */
  agentHighlightCopy?: string;

  customLoadingSteps?: Step[];

  customTags?: {
    [key: string]: ReactNode;
  };

  /** @see @xyla/ask-oe/AskOEInternalContext.tsx */
  renderFeedbackButtonsBelowReferences?: boolean;

  /** @see @xyla/ask-oe/AskOEInternalContext.tsx */
  enableStickyAskBar?: boolean;

  /** Is the article currently being typed out by the typewriter? */
  isTyping?: boolean;

  /** Should the article use the partial accordion component for references? */
  usePartialAccordion?: boolean;

  /** Immer updater fn. Should be called when an interactive component needs to update the ArticleData. */
  setArticleData?: (updateFn: DraftFunction<ArticleData>) => void;
}

/** Holds global article configuration options */
const ArticleContext = createContext<ArticleContextType>({});
const ArticleContextProvider = ArticleContext.Provider;
const useArticleContext = () => {
  return useContext(ArticleContext);
};

export {
  PlotlyProvider,
  usePlotlyContext,
  LinkProvider,
  useLinkContext,
  ArticleContextProvider,
  useArticleContext,
};
