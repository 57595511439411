// DO NOT MODIFY THIS FILE. IT WAS GENERATED BY GPT-4. ANY CHANGES WILL BE OVERWRITTEN.
import { registerCalculator } from '../registry';
import { CalculatorOutputValue, CalculatorOutput } from '../types';

export const SIRSSepsisShockCalculator = (values: {
  temperature?: boolean;
  heart_rate?: boolean;
  resp?: boolean;
  wbc?: boolean;
  infection?: boolean;
  lactic_acidosis?: boolean;
  sbp?: boolean;
  severe_sepsis?: boolean;
  organ_failure?: boolean;
}): CalculatorOutput => {
  const {
    temperature,
    heart_rate,
    resp,
    wbc,
    infection,
    lactic_acidosis,
    sbp,
    severe_sepsis,
    organ_failure,
  } = values;

  if (
    temperature === null ||
    temperature === undefined ||
    heart_rate === null ||
    heart_rate === undefined ||
    resp === null ||
    resp === undefined ||
    wbc === null ||
    wbc === undefined ||
    infection === null ||
    infection === undefined ||
    lactic_acidosis === null ||
    lactic_acidosis === undefined ||
    sbp === null ||
    sbp === undefined ||
    severe_sepsis === null ||
    severe_sepsis === undefined ||
    organ_failure === null ||
    organ_failure === undefined
  ) {
    return null;
  }

  // SIRS Criteria (≥2 meets SIRS definition)
  let sirs_active = false;
  if (
    Number(temperature) + Number(heart_rate) + Number(resp) + Number(wbc) >=
    2
  ) {
    sirs_active = Boolean(temperature) || Boolean(wbc);
  }

  // Sepsis Criteria (SIRS + Infection)
  const sepsis_active = sirs_active && Boolean(infection);

  // Severe Sepsis Criteria (Sepsis + ≥1 following Dysfunction Criteria)
  const severe_sepsis_active =
    sirs_active && (Boolean(lactic_acidosis) || Boolean(sbp));

  // Septic Shock Criteria (Severe Sepsis + Hypotension)
  const septic_shock_active = sirs_active && Boolean(severe_sepsis);

  // Multiple Organ Dysfunction Syndrome (MODS) Criteria (Severe Sepsis + ≥2 Organ Failures)
  const mods_active = sirs_active && Boolean(organ_failure);

  let return_message = '';
  if (mods_active) {
    return_message =
      'The patient meets the criteria for Multiple Organ Dysfunction Syndrome (MODS).';
  } else if (septic_shock_active) {
    return_message = 'The patient meets the criteria for Septic Shock.';
  } else if (severe_sepsis_active) {
    return_message = 'The patient meets the criteria for Severe Sepsis.';
  } else if (sepsis_active) {
    return_message = 'The patient meets the criteria for Sepsis.';
  } else if (sirs_active) {
    return_message =
      'The patient meets the criteria for Systemic Inflammatory Response Syndrome (SIRS).';
  } else {
    return_message =
      'The patient does not meet the criteria for SIRS, Sepsis, or Septic Shock.';
  }

  return {
    sirs_sepsis_shock: {
      prefix: '',
      value: return_message,
    },
  };
};

registerCalculator(
  'sirs-sepsis-septic-shock',
  SIRSSepsisShockCalculator,
  'SIRS, Sepsis, and Septic Shock'
);
