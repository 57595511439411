// DO NOT MODIFY THIS FILE. IT WAS GENERATED BY GPT-4. ANY CHANGES WILL BE OVERWRITTEN.
import { registerCalculator } from '../registry';
import { CalculatorOutputValue, CalculatorOutput } from '../types';

export const PERCRuleCalculator = (values: {
  age?: boolean;
  heart_rate?: boolean;
  oxygen_saturation?: boolean;
  unilateral_leg_swelling?: boolean;
  hemoptysis?: boolean;
  recent_surgery_or_trauma?: boolean;
  prior_pe_or_dvt?: boolean;
  hormone_use?: boolean;
}): CalculatorOutput => {
  const {
    age = false,
    heart_rate = false,
    oxygen_saturation = false,
    unilateral_leg_swelling = false,
    hemoptysis = false,
    recent_surgery_or_trauma = false,
    prior_pe_or_dvt = false,
    hormone_use = false,
  } = values;

  let message = 'Pulmonary Embolism is Unlikely';

  if (
    age ||
    heart_rate ||
    oxygen_saturation ||
    unilateral_leg_swelling ||
    hemoptysis ||
    recent_surgery_or_trauma ||
    prior_pe_or_dvt ||
    hormone_use
  ) {
    message = 'PERC Cannot Rule Out Pulmonary Embolism';
  }

  return {
    perc: {
      prefix: '',
      value: message,
    },
  };
};

registerCalculator(
  'perc',
  PERCRuleCalculator,
  'PERC Rule for Pulmonary Embolism'
);
