// DO NOT MODIFY THIS FILE. IT WAS GENERATED BY GPT-4. ANY CHANGES WILL BE OVERWRITTEN.
import { registerCalculator } from '../registry';
import { CalculatorOutputValue, CalculatorOutput } from '../types';

export const GoFarCalculator = (values: {
  age?: '<70' | '70-74' | '75-79' | '80-84' | '85+';
  stroke?: boolean;
  hepatic_insufficiency?: boolean;
  hypotension_or_hypoperfusion?: boolean;
  metastatic_or_hema_cancer?: boolean;
  trauma?: boolean;
  pneumonia?: boolean;
  renal_insufficiency?: boolean;
  respiratory_insufficiency?: boolean;
  sepsis?: boolean;
  nursing_facility?: boolean;
  medical_noncardiac?: boolean;
  neurologically_intact?: boolean;
}): CalculatorOutput => {
  const {
    age,
    stroke,
    hepatic_insufficiency,
    hypotension_or_hypoperfusion,
    metastatic_or_hema_cancer,
    trauma,
    pneumonia,
    renal_insufficiency,
    respiratory_insufficiency,
    sepsis,
    nursing_facility,
    medical_noncardiac,
    neurologically_intact,
  } = values;

  if (
    age === null ||
    age === undefined ||
    stroke === null ||
    stroke === undefined ||
    hepatic_insufficiency === null ||
    hepatic_insufficiency === undefined ||
    hypotension_or_hypoperfusion === null ||
    hypotension_or_hypoperfusion === undefined ||
    metastatic_or_hema_cancer === null ||
    metastatic_or_hema_cancer === undefined ||
    trauma === null ||
    trauma === undefined ||
    pneumonia === null ||
    pneumonia === undefined ||
    renal_insufficiency === null ||
    renal_insufficiency === undefined ||
    respiratory_insufficiency === null ||
    respiratory_insufficiency === undefined ||
    sepsis === null ||
    sepsis === undefined ||
    nursing_facility === null ||
    nursing_facility === undefined ||
    medical_noncardiac === null ||
    medical_noncardiac === undefined ||
    neurologically_intact === null ||
    neurologically_intact === undefined
  ) {
    return null;
  }

  let score = 0;
  score +=
    {
      '<70': 0,
      '70-74': 2,
      '75-79': 5,
      '80-84': 6,
      '85+': 11,
    }[age] || 0;

  score += stroke ? 8 : 0;
  score += hepatic_insufficiency ? 6 : 0;
  score += hypotension_or_hypoperfusion ? 5 : 0;
  score += metastatic_or_hema_cancer ? 7 : 0;
  score += trauma ? 10 : 0;
  score += pneumonia ? 1 : 0;
  score += renal_insufficiency ? 4 : 0;
  score += respiratory_insufficiency ? 4 : 0;
  score += sepsis ? 7 : 0;
  score += nursing_facility ? 6 : 0;
  score += medical_noncardiac ? 7 : 0;
  score -= neurologically_intact ? 15 : 0;

  return {
    go_far: {
      prefix: 'GO-FAR Score',
      value: score,
    },
  };
};

registerCalculator('gofar', GoFarCalculator, 'GO-FAR Score');
