// DO NOT MODIFY THIS FILE. IT WAS GENERATED BY GPT-4. ANY CHANGES WILL BE OVERWRITTEN.
import { registerCalculator } from '../registry';
import { CalculatorOutputValue, CalculatorOutput } from '../types';

export const PHQ9Calculator = (values: {
  little_interest?: number;
  feeling_down?: number;
  trouble_sleeping?: number;
  feeling_tired?: number;
  poor_appetite?: number;
  feeling_bad_about_self?: number;
  trouble_concentrating?: number;
  moving_slowly?: number;
  thoughts_of_self_harm?: number;
}): CalculatorOutput => {
  const {
    little_interest = 0,
    feeling_down = 0,
    trouble_sleeping = 0,
    feeling_tired = 0,
    poor_appetite = 0,
    feeling_bad_about_self = 0,
    trouble_concentrating = 0,
    moving_slowly = 0,
    thoughts_of_self_harm = 0,
  } = values;

  if (
    little_interest === null ||
    little_interest === undefined ||
    feeling_down === null ||
    feeling_down === undefined ||
    trouble_sleeping === null ||
    trouble_sleeping === undefined ||
    feeling_tired === null ||
    feeling_tired === undefined ||
    poor_appetite === null ||
    poor_appetite === undefined ||
    feeling_bad_about_self === null ||
    feeling_bad_about_self === undefined ||
    trouble_concentrating === null ||
    trouble_concentrating === undefined ||
    moving_slowly === null ||
    moving_slowly === undefined ||
    thoughts_of_self_harm === null ||
    thoughts_of_self_harm === undefined
  ) {
    return null;
  }

  const phq_score =
    little_interest +
    feeling_down +
    trouble_sleeping +
    feeling_tired +
    poor_appetite +
    feeling_bad_about_self +
    trouble_concentrating +
    moving_slowly +
    thoughts_of_self_harm;

  return {
    phq_9: {
      prefix: 'PHQ-9 Score',
      value: phq_score,
    },
  };
};

registerCalculator('phq9', PHQ9Calculator, 'PHQ-9 for Depression');
