// DO NOT MODIFY THIS FILE. IT WAS GENERATED BY GPT-4. ANY CHANGES WILL BE OVERWRITTEN.
import { registerCalculator } from '../registry';
import { CalculatorOutputValue, CalculatorOutput } from '../types';

export const QTcCalculator = (values: {
  qt_interval?: number | null;
  heart_rate?: number | null;
  formula?: 'bazett' | 'framingham' | 'fridericia' | 'hodges' | 'rautaharju';
}): CalculatorOutput => {
  const { qt_interval, heart_rate, formula = 'bazett' } = values;

  if (qt_interval == null || heart_rate == null || formula == null) {
    return null;
  }

  let result: number | null = null;

  switch (formula) {
    case 'bazett':
      result = qt_interval / Math.sqrt(60 / heart_rate);
      break;
    case 'framingham':
      result = qt_interval + 154 * (1 - 60 / heart_rate);
      break;
    case 'fridericia':
      result = qt_interval / Math.cbrt(60 / heart_rate);
      break;
    case 'hodges':
      result = qt_interval + 1.75 * (60 / (60 / heart_rate) - 60);
      break;
    case 'rautaharju':
      result = (qt_interval * (120 + heart_rate)) / 180;
      break;
    default:
      return null;
  }

  const rounded_result = Math.round(result * 100) / 100;

  return {
    qtc_interval: {
      prefix: 'Corrected QT Interval',
      value: rounded_result,
      suffix: 'ms',
    },
  };
};

registerCalculator(
  'corrected-qtc',
  QTcCalculator,
  'Corrected QT Interval (QTc)'
);
