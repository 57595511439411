import { useMemo } from 'react';
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
} from '@mui/x-data-grid';
import { CITATION_LINK_SUFFIX, CtrlLink } from '../../citations';
import DomPurify from 'isomorphic-dompurify';

/** converts a citation to a list of numbers, e.g. "[1,2,5-7]" -> [1,2,5,6,7] */
export function expandStringToList(str: string): number[] {
  str = str.replace(/\s/g, '').slice(1, -1); // remove spaces and brackets

  const ranges = str.split(',');
  const expandedNumbers = [];

  for (let range of ranges) {
    if (range.includes('-')) {
      const [start, end] = range.split('-').map(Number);
      for (let i = start; i <= end; i++) {
        expandedNumbers.push(i);
      }
    } else {
      expandedNumbers.push(Number(range));
    }
  }

  return expandedNumbers;
}

/**
 * Code for splitting text based on where the brackets are. Designed so that citations end up in their own array elements,
 * so that we can keep track of which parts of the string are citations and which or not.
 *
 * Opening brackets are always at the start of their array element, and closing brackets are at the end.
 *
 * Example:
 * splitByBracket("This is a [citation] and [another citation]") -> ["This is a ", "[citation]", " and ", "[another citation]"]
 * splitByBracket("Here is a [comment with [citation]]") -> ["Here is a ", "[comment with ", "[citation]", "]"]
 * @param text Text to split by bracket
 * @returns List of strings, split by where the brackets are
 */
export function splitByBracket(text: string): string[] {
  const chars = [...text];
  const splitTerms = [''];
  chars.forEach((char) => {
    if (char === '[') {
      splitTerms.push('[');
    } else if (char === ']') {
      splitTerms[splitTerms.length - 1] += ']';
      splitTerms.push('');
    } else {
      splitTerms[splitTerms.length - 1] += char;
    }
  });
  return splitTerms.filter((term) => term !== '');
}

function citationKey(citationText: string, questionIndex: number): string {
  const minCitationNumber = Math.min(...expandStringToList(citationText));
  return `${questionIndex}_${minCitationNumber}${CITATION_LINK_SUFFIX}`;
}

interface TableProps {
  table_data: Array<{ [key: string]: any }>;
  citation_data: any;
  questionIndex?: number;
  askWrapperContainerId?: string;
}

export const ArticleTable = ({
  data,
  styles,
}: {
  data: TableProps;
  styles: any;
}) => {
  const { table_data, citation_data, questionIndex, askWrapperContainerId } =
    data;

  /** returns a list of column defs for the table. Includes a renderer for citation links
   * and a formatter to convert citations to references for the csv export */
  const columns: GridColDef[] = useMemo(() => {
    if (!table_data || !table_data[0]) return [];

    const citationRegex = /\[\d+(?:-\d+)?(?:, \d+(?:-\d+)?)*\]/g;
    const hrefRegex = /<a\s+(?:[^>]*?\s+)?href=(["'])(.*?)\1[^>]*>(.*?)<\/a>/gi;

    return Object.keys(table_data[0]).map((key, index) => ({
      field: key,
      headerName: key,
      sortable: false,
      flex: index < Object.keys(table_data[0]).length - 1 ? 1 : 0,
      valueFormatter: ({ value }) => {
        if (typeof value !== 'string') return value;
        return splitByBracket(value)
          .map((term) => {
            return (
              term?.match(citationRegex)?.map(
                (match: string) =>
                  `${expandStringToList(match)
                    .map((match: number) =>
                      citation_data.referencesArr[match - 1]?.citation.replace(
                        hrefRegex,
                        '$3. $2'
                      )
                    )
                    .join(', ')}`
              ) ?? term
            );
          })
          .join('');
      },
      renderCell: ({ id, value }: GridRenderCellParams) => {
        if (typeof value !== 'string') return value;
        const cell_values_with_citations = splitByBracket(value)
          .map((term) => {
            return (
              term?.match(citationRegex)?.map((match: string) => (
                <span
                  className={`${styles.citations} brandable--citation`}
                  key={`citation-data-${index}-cell-${id}`}
                >
                  <CtrlLink
                    to={
                      questionIndex != null
                        ? citationKey(match, questionIndex)
                        : '1'
                    }
                    offset={0}
                    containerId={askWrapperContainerId}
                  >
                    {match}
                  </CtrlLink>
                </span>
              )) ?? (
                <span
                  key={`text-data-${index}-cell-${id}`}
                  dangerouslySetInnerHTML={{ __html: DomPurify.sanitize(term) }}
                ></span>
              )
            );
          })
          .flat();
        return <div>{cell_values_with_citations}</div>;
      },
    }));
  }, [
    table_data,
    citation_data,
    styles.citations,
    questionIndex,
    askWrapperContainerId,
  ]);

  const rows = useMemo(() => {
    if (!table_data) return [];
    return table_data.map((row, index) => ({ ...row, id: index }));
  }, [table_data]);

  if (!table_data || !table_data[0]) {
    return <span>Error parsing table.</span>;
  }

  const Toolbar = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarFilterButton />
        <GridToolbarExport
          printOptions={{
            // print is broken in mui atm
            disableToolbarButton: true,
          }}
          csvOptions={{
            getRowsToExport: () => Array.from(table_data.keys()),
          }}
        />
      </GridToolbarContainer>
    );
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
      }}
    >
      <DataGrid
        rows={rows}
        columns={columns}
        getRowHeight={() => 'auto'}
        hideFooter={true}
        slots={{ toolbar: Toolbar }}
        autoHeight
        density='compact'
        disableRowSelectionOnClick
        disableColumnMenu
        sx={{
          '& .MuiDataGrid-columnHeaderTitle': {
            whiteSpace: 'normal',
            lineHeight: 'normal',
            marginBottom: '8px !important',
          },
          '& .MuiDataGrid-columnHeader': {
            height: 'unset !important',
          },
          '& .MuiDataGrid-columnHeaders': {
            maxHeight: '200px !important',
          },
          '& .MuiDataGrid-toolbarContainer': {
            '& .MuiButton-text': {
              color: 'grey.700',
            },
          },
        }}
      />
    </div>
  );
};
