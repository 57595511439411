// DO NOT MODIFY THIS FILE. IT WAS GENERATED BY GPT-4. ANY CHANGES WILL BE OVERWRITTEN.
import { registerCalculator } from '../registry';
import { CalculatorOutputValue, CalculatorOutput } from '../types';

export const STOPBANGCalculator = (values: {
  age?: number | null;
  gender?: 'male' | 'female' | null;
  neck_circumference?: number | null;
  bmi?: number | null;
  snoring?: boolean | null;
  tiredness?: boolean | null;
  observed_apnea?: boolean | null;
  hypertension?: boolean | null;
}): CalculatorOutput => {
  const {
    age,
    gender,
    neck_circumference,
    bmi,
    snoring,
    tiredness,
    observed_apnea,
    hypertension,
  } = values;

  if (
    age == null ||
    gender == null ||
    neck_circumference == null ||
    bmi == null ||
    snoring == null ||
    tiredness == null ||
    observed_apnea == null ||
    hypertension == null
  ) {
    return null;
  }

  let score = 0;
  if (gender === 'male') {
    score += 1;
  }

  score += age;
  score += neck_circumference;
  score += bmi;
  score += snoring ? 1 : 0;
  score += tiredness ? 1 : 0;
  score += observed_apnea ? 1 : 0;
  score += hypertension ? 1 : 0;

  return {
    stop_bang: {
      prefix: 'STOP-BANG Score',
      value: score,
    },
  };
};

registerCalculator('stop-bang', STOPBANGCalculator, 'STOP-BANG');
