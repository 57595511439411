// DO NOT MODIFY THIS FILE. IT WAS GENERATED BY GPT-4. ANY CHANGES WILL BE OVERWRITTEN.
import { registerCalculator } from '../registry';
import { CalculatorOutputValue, CalculatorOutput } from '../types';

export const Fib4IndexCalculator = (values: {
  age?: number | null;
  ast?: number | null;
  alt?: number | null;
  platelet_count?: number | null;
}): CalculatorOutput => {
  const { age, ast, alt, platelet_count } = values;

  // Check for null or undefined values
  if (age == null || ast == null || alt == null || platelet_count == null) {
    return null;
  }

  // FIB-4 Score = (Age * AST) / (Platelets * √(ALT))
  const fib4_score = (age * ast) / (platelet_count * Math.sqrt(alt));
  const rounded_fib4_score = Math.round(fib4_score * 100) / 100;

  return {
    fib_4: {
      prefix: 'FIB-4 Score',
      value: rounded_fib4_score,
    },
  };
};

registerCalculator('fib4', Fib4IndexCalculator, 'FIB-4 Index');
