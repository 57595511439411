import { Typography } from '@mui/material';
import type { ReactNode } from 'react';

export function CalculatorHeading({ children }: { children?: ReactNode }) {
  return (
    <Typography
      component='h2'
      sx={{ fontSize: '1.25rem', fontWeight: 600, mb: '0.5em' }}
    >
      {children}
    </Typography>
  );
}
