// DO NOT MODIFY THIS FILE. IT WAS GENERATED BY GPT-4. ANY CHANGES WILL BE OVERWRITTEN.
import { registerCalculator } from '../registry';
import { CalculatorOutputValue, CalculatorOutput } from '../types';

export const CentorScoreCalculator = (values: {
  age?: number | null;
  fever?: boolean | null;
  tonsillar_exudates?: boolean | null;
  anterior_cervical_lymphadenopathy?: boolean | null;
  has_cough?: boolean | null;
}): CalculatorOutput => {
  const {
    age,
    fever,
    tonsillar_exudates,
    anterior_cervical_lymphadenopathy,
    has_cough,
  } = values;

  if (
    age === null ||
    age === undefined ||
    fever === null ||
    fever === undefined ||
    tonsillar_exudates === null ||
    tonsillar_exudates === undefined ||
    anterior_cervical_lymphadenopathy === null ||
    anterior_cervical_lymphadenopathy === undefined ||
    has_cough === null ||
    has_cough === undefined
  ) {
    return null;
  }

  let score = 0;
  score +=
    {
      0: 1,
      1: 0,
      2: -1,
    }[age] || 0;
  score += fever ? 1 : 0;
  score += tonsillar_exudates ? 1 : 0;
  score += anterior_cervical_lymphadenopathy ? 1 : 0;
  score += !has_cough ? 1 : 0;

  return {
    centor: {
      prefix: 'Centor Score',
      value: score,
    },
  };
};

registerCalculator(
  'centor-score',
  CentorScoreCalculator,
  'Centor Score for Strep Throat (Modified)'
);
