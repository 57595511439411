import {
  Typography,
  Stack,
  ToggleButtonGroup,
  ToggleButton,
} from '@mui/material';

export interface Option {
  label: string;
  value: string;
  description: string;
}

interface CalculatorButtonGroupProps {
  options: Option[];
  selectedValue: string | number | null;
  handleClick: (value: string | null) => void;
  display: 'vertical' | 'horizontal' | 'dropdown' | 'fully_horizontal';
  disabled?: boolean;
}

/** Displays a group of toggle buttons for a single calculator control */
export const CalculatorButtonGroup: React.FC<CalculatorButtonGroupProps> = ({
  options,
  selectedValue,
  handleClick,
  display = 'horizontal',
  disabled,
}) => {
  const handleChange = (_event: any, value: string | null) => {
    handleClick(value);
  };

  return (
    <ToggleButtonGroup
      orientation={display === 'vertical' ? 'vertical' : 'horizontal'}
      value={selectedValue}
      exclusive
      onChange={handleChange}
      sx={{ width: '100%' }}
      disabled={disabled}
    >
      {options.map((option) => (
        <ToggleButton
          key={option.label}
          value={option.value}
          sx={{
            textTransform: 'none',
            flex: 1,
          }}
          disableRipple
        >
          <Stack
            direction='row'
            spacing={1}
            alignItems='baseline'
            sx={{ width: '100%' }}
          >
            <Typography
              variant='body2'
              sx={[
                { flex: 1, textAlign: 'left' },
                // Bold if selected
                option.value === selectedValue && {
                  fontWeight: 500,
                },
              ]}
            >
              {option.label}
            </Typography>
            <Typography
              variant='body2'
              sx={[
                // Bold if selected
                option.value === selectedValue && {
                  fontWeight: 500,
                },
              ]}
            >
              {option.description}
            </Typography>
          </Stack>
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};
