// DO NOT MODIFY THIS FILE. IT WAS GENERATED BY GPT-4. ANY CHANGES WILL BE OVERWRITTEN.
import { registerCalculator } from '../registry';
import { CalculatorOutputValue, CalculatorOutput } from '../types';

export const EGFRCalculator = (values: {
  age?: number | null;
  sex?: 'female' | 'male' | null;
  serum_creatinine?: number | null;
  serum_cystatin_c?: number | null;
}): CalculatorOutput => {
  const { age, sex, serum_creatinine, serum_cystatin_c } = values;

  if (age == null || sex == null || serum_creatinine == null) {
    return null;
  }

  let A: number, B: number, C: number, D: number;

  if (serum_cystatin_c == null) {
    if (sex === 'female' && serum_creatinine <= 0.7) {
      A = 0.7;
      B = -0.241;
    } else if (sex === 'female' && serum_creatinine > 0.7) {
      A = 0.7;
      B = -1.2;
    } else if (sex === 'male' && serum_creatinine <= 0.9) {
      A = 0.9;
      B = -0.302;
    } else if (sex === 'male' && serum_creatinine > 0.9) {
      A = 0.9;
      B = -1.2;
    } else {
      return null;
    }

    const creatinine_egfr =
      142 *
      Math.pow(serum_creatinine / A, B) *
      Math.pow(0.9938, age) *
      (sex === 'female' ? 1.012 : 1);
    const rounded_creatinine_egfr = Math.round(creatinine_egfr * 100) / 100;

    return {
      creatinine_based_egfr: {
        prefix: 'Creatinine Based eGFR',
        value: rounded_creatinine_egfr,
        suffix: 'mL/min/1.73m²',
      },
    };
  }

  if (sex === 'female' && serum_creatinine <= 0.7 && serum_cystatin_c <= 0.8) {
    A = 0.7;
    B = -0.219;
    C = 0.8;
    D = -0.323;
  } else if (
    sex === 'female' &&
    serum_creatinine > 0.7 &&
    serum_cystatin_c <= 0.8
  ) {
    A = 0.7;
    B = -0.544;
    C = 0.8;
    D = -0.323;
  } else if (
    sex === 'female' &&
    serum_creatinine <= 0.7 &&
    serum_cystatin_c > 0.8
  ) {
    A = 0.7;
    B = -0.219;
    C = 0.8;
    D = -0.778;
  } else if (
    sex === 'female' &&
    serum_creatinine > 0.7 &&
    serum_cystatin_c > 0.8
  ) {
    A = 0.7;
    B = -0.544;
    C = 0.8;
    D = -0.778;
  } else if (
    sex === 'male' &&
    serum_creatinine <= 0.9 &&
    serum_cystatin_c <= 0.8
  ) {
    A = 0.9;
    B = -0.144;
    C = 0.8;
    D = -0.323;
  } else if (
    sex === 'male' &&
    serum_creatinine > 0.9 &&
    serum_cystatin_c <= 0.8
  ) {
    A = 0.9;
    B = -0.544;
    C = 0.8;
    D = -0.323;
  } else if (
    sex === 'male' &&
    serum_creatinine <= 0.9 &&
    serum_cystatin_c > 0.8
  ) {
    A = 0.9;
    B = -0.144;
    C = 0.8;
    D = -0.778;
  } else if (
    sex === 'male' &&
    serum_creatinine > 0.9 &&
    serum_cystatin_c > 0.8
  ) {
    A = 0.9;
    B = -0.544;
    C = 0.8;
    D = -0.778;
  } else {
    return null;
  }

  const cystatin_c_egfr =
    135 *
    Math.pow(serum_creatinine / A, B) *
    Math.pow(serum_cystatin_c / C, D) *
    Math.pow(0.9961, age) *
    (sex === 'female' ? 0.963 : 1);
  const rounded_cystatin_c_egfr = Math.round(cystatin_c_egfr * 100) / 100;

  return {
    creatinine_cystatin_c_based_egfr: {
      prefix: 'Creatinine-Cystatin C Based eGFR',
      value: rounded_cystatin_c_egfr,
      suffix: 'mL/min/1.73m²',
    },
  };
};

registerCalculator('egfr', EGFRCalculator, 'CKD-EPI');
