// DO NOT MODIFY THIS FILE. IT WAS GENERATED BY GPT-4. ANY CHANGES WILL BE OVERWRITTEN.
import { registerCalculator } from '../registry';
import { CalculatorOutputValue, CalculatorOutput } from '../types';

export const IdealBodyWeightCalculator = (values: {
  sex?: 'male' | 'female' | null;
  height?: number | null;
  actual_body_weight?: number | null;
}): CalculatorOutput => {
  const { sex, height, actual_body_weight } = values;

  if (
    sex === null ||
    sex === undefined ||
    height === null ||
    height === undefined
  ) {
    return null;
  }

  let ideal_body_weight = 0.0;
  if (sex === 'male') {
    ideal_body_weight = 50.0 + 2.3 * (height - 60);
  } else {
    ideal_body_weight = 45.5 + 2.3 * (height - 60);
  }

  const rounded_ibw_lbs = Math.round(ideal_body_weight * 2.20462 * 100) / 100;
  const to_return: CalculatorOutput = {
    ibw: {
      prefix: 'Ideal Body Weight',
      value: rounded_ibw_lbs,
      suffix: 'lbs',
    },
  };

  if (
    actual_body_weight !== null &&
    actual_body_weight !== undefined &&
    ideal_body_weight < actual_body_weight
  ) {
    const abw =
      (actual_body_weight - ideal_body_weight) * 0.4 + ideal_body_weight;
    const rounded_abw_lbs = Math.round(abw * 2.20462 * 100) / 100;
    to_return['abw'] = {
      prefix: 'Adjusted Body Weight',
      value: rounded_abw_lbs,
      suffix: 'lbs',
    };
  }

  return to_return;
};

registerCalculator(
  'ideal-body-weight',
  IdealBodyWeightCalculator,
  'Ideal Body Weight and Adjusted Body Weight Calculator'
);
