// DO NOT MODIFY THIS FILE. IT WAS GENERATED BY GPT-4. ANY CHANGES WILL BE OVERWRITTEN.
import { registerCalculator } from '../registry';
import { CalculatorOutputValue, CalculatorOutput } from '../types';

export const SodiumCorrectionHyperglycemiaCalculator = (values: {
  sodium: number | null | undefined;
  glucose: number | null | undefined;
}): CalculatorOutput => {
  const { sodium, glucose } = values;

  if (sodium == null || glucose == null) {
    return null;
  }

  const katz = sodium + 0.016 * (glucose - 100);
  const hillier = sodium + 0.024 * (glucose - 100);

  return {
    katz: {
      prefix: 'Katz Formula',
      value: katz,
      suffix: 'mEq/L',
    },
    hillier: {
      prefix: 'Hillier Formula',
      value: hillier,
      suffix: 'mEq/L',
    },
  };
};

registerCalculator(
  'sodium-correction-hyperglycemia',
  SodiumCorrectionHyperglycemiaCalculator,
  'Sodium Correction for Hyperglycemia'
);
