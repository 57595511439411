// DO NOT MODIFY THIS FILE. IT WAS GENERATED BY GPT-4. ANY CHANGES WILL BE OVERWRITTEN.
import { registerCalculator } from '../registry';
import { CalculatorOutputValue, CalculatorOutput } from '../types';

export const PESICalculator = (values: {
  age?: number | null;
  sex?: 'male' | 'female' | null;
  history_of_cancer?: boolean | null;
  history_of_heart_failure?: boolean | null;
  history_of_chronic_lung_disease?: boolean | null;
  heart_rate?: boolean | null;
  systolic_blood_pressure?: boolean | null;
  respiratory_rate?: boolean | null;
  temperature?: boolean | null;
  altered_mental_status?: boolean | null;
  arterial_oxygen_saturation?: boolean | null;
}): CalculatorOutput => {
  const {
    age,
    sex,
    history_of_cancer,
    history_of_heart_failure,
    history_of_chronic_lung_disease,
    heart_rate,
    systolic_blood_pressure,
    respiratory_rate,
    temperature,
    altered_mental_status,
    arterial_oxygen_saturation,
  } = values;

  if (
    age == null ||
    sex == null ||
    history_of_cancer == null ||
    history_of_heart_failure == null ||
    history_of_chronic_lung_disease == null ||
    heart_rate == null ||
    systolic_blood_pressure == null ||
    respiratory_rate == null ||
    temperature == null ||
    altered_mental_status == null ||
    arterial_oxygen_saturation == null
  ) {
    return null;
  }

  let pesi_score = 0;
  pesi_score += age;
  if (sex === 'male') {
    pesi_score += 10;
  }

  if (history_of_cancer) {
    pesi_score += 30;
  }

  if (history_of_heart_failure) {
    pesi_score += 10;
  }

  if (history_of_chronic_lung_disease) {
    pesi_score += 10;
  }

  if (heart_rate) {
    pesi_score += 20;
  }

  if (systolic_blood_pressure) {
    pesi_score += 30;
  }

  if (respiratory_rate) {
    pesi_score += 20;
  }

  if (temperature) {
    pesi_score += 20;
  }

  if (altered_mental_status) {
    pesi_score += 60;
  }

  if (arterial_oxygen_saturation) {
    pesi_score += 20;
  }

  return {
    pesi_score: {
      prefix: 'PESI Score',
      value: pesi_score,
    },
  };
};

registerCalculator(
  'pesi',
  PESICalculator,
  'PESI (Pulmonary Embolism Severity Index)'
);
