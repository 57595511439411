// OmniCalculator.tsx

import React, { ReactNode, Fragment } from 'react';
import { CalculatorButtonGroup } from './CalculatorButtonGroup';
import { InputWithUnits } from './InputWithUnits';
import {
  Typography,
  Divider,
  Stack,
  Unstable_Grid2 as Grid,
} from '@mui/material';
import { WhenToUse } from './WhenToUse';
import { Formula } from './Formula';
import {
  ChoicesParameter,
  FloatParameter,
  CalculatorDefinition,
  CalculatorData,
} from './types';
import { CalculatorRowLabel } from './CalculatorHelpers/CalculatorRowLabel';
import { CalculatorHeading } from './CalculatorHelpers/CalculatorHeading';
import { CalculatorCard } from './CalculatorCard';
import { CalculatorDropdownParameter } from './CalculatorDropdownParameter';

const responsiveSpacing = { xs: 2, md: 3 };

interface OmniCalculatorProps {
  /** The static spec of the calculator that defines all available options */
  calculatorDefinition: CalculatorDefinition;
  /** The actual data state of the current calculator in this article */
  calculatorData: CalculatorData;
  onValuesChange: (values: { [key: string]: any }) => void;
  whenToUseSectionElement: ReactNode | null;
  formulaSectionElement: ReactNode | null;
  disabled?: boolean;
}

export const OmniCalculator: React.FC<OmniCalculatorProps> = ({
  calculatorDefinition,
  calculatorData,
  onValuesChange,
  whenToUseSectionElement,
  formulaSectionElement,
  disabled,
}) => {
  const handleParameterChange = (paramName: string, value: any) => {
    const newValues = {
      ...calculatorData.parsed_values,
      [paramName]: value,
    };
    onValuesChange(newValues);
  };

  if (!calculatorDefinition) {
    return null;
  }

  return (
    <>
      <CalculatorCard>
        <CalculatorHeading>{calculatorDefinition.title}</CalculatorHeading>
        <Typography color='text.secondary' sx={{ mb: 2 }}>
          {calculatorDefinition.single_line_description}
        </Typography>

        {/* When to Use Section */}
        <WhenToUse whenToUseSectionElement={whenToUseSectionElement} />

        {/* Formula, top border if when to use is NULL */}
        {calculatorData.show_formula && (
          <Formula
            formulaSectionElement={formulaSectionElement}
            showTopBorder={!whenToUseSectionElement}
          />
        )}

        {/* List of calculator controls */}
        <Stack sx={{ gap: responsiveSpacing, my: responsiveSpacing }}>
          {calculatorDefinition.parameters.map((param, index) => {
            let paramDisplay: ReactNode;
            if (param.type === 'ChoicesParameter') {
              const choiceParam = param as ChoicesParameter;
              const value = calculatorData.parsed_values?.[param.name] ?? null;

              const RenderFn =
                choiceParam.display === 'dropdown'
                  ? CalculatorDropdownParameter
                  : CalculatorButtonGroup;

              paramDisplay = (
                <RenderFn
                  options={choiceParam.choices}
                  display={choiceParam.display}
                  selectedValue={value}
                  handleClick={(value) =>
                    handleParameterChange(param.name, value)
                  }
                  disabled={disabled}
                />
              );
            } else if (param.type === 'FloatParameter') {
              const floatParam = param as FloatParameter;
              const value = calculatorData.parsed_values?.[param.name] ?? null;
              paramDisplay = (
                <InputWithUnits
                  label={param.display_name}
                  value={value}
                  onChange={(value) => handleParameterChange(param.name, value)}
                  units={floatParam.units}
                  disabled={disabled}
                />
              );
            }

            // Render each row inside a Grid component for responsive alignment
            return (
              <Fragment key={param.name}>
                <Grid container spacing={responsiveSpacing}>
                  <Grid xs={12} sm={8} md={6}>
                    <CalculatorRowLabel
                      label={param.display_name}
                      description={param.description}
                    />
                  </Grid>
                  <Grid xs>{paramDisplay}</Grid>
                </Grid>

                {index < calculatorDefinition.parameters.length - 1 && (
                  <Divider />
                )}
              </Fragment>
            );
          })}
        </Stack>
      </CalculatorCard>
    </>
  );
};
