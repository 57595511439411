// DO NOT MODIFY THIS FILE. IT WAS GENERATED BY GPT-4. ANY CHANGES WILL BE OVERWRITTEN.
import { registerCalculator } from '../registry';
import { CalculatorOutputValue, CalculatorOutput } from '../types';

export const FENaCalculator = (values: {
  serum_sodium?: number | null;
  serum_creatinine?: number | null;
  urine_sodium?: number | null;
  urine_creatinine?: number | null;
}): CalculatorOutput => {
  const { serum_sodium, serum_creatinine, urine_sodium, urine_creatinine } =
    values;

  if (
    serum_sodium == null ||
    serum_creatinine == null ||
    urine_sodium == null ||
    urine_creatinine == null
  ) {
    return null;
  }

  const fena =
    (100 * (serum_creatinine * urine_sodium)) /
    (serum_sodium * urine_creatinine);
  const rounded_fena = Math.round(fena * 100) / 100;

  return {
    fena: {
      prefix: 'Fractional Excretion of Sodium',
      value: rounded_fena,
      suffix: '%',
    },
  };
};

registerCalculator(
  'fena',
  FENaCalculator,
  'FENa (Fractional Excretion of Sodium)'
);
