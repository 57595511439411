// WhenToUse.tsx

import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CalculateIcon from '@mui/icons-material/Calculate';
import { ReactNode } from 'react';

interface FormulaProps {
  formulaSectionElement: ReactNode | null;
  showTopBorder?: boolean;
}

export const Formula: React.FC<FormulaProps> = ({
  formulaSectionElement,
  showTopBorder,
}) => {
  if (formulaSectionElement === null) {
    return null;
  }

  return (
    <Accordion
      disableGutters
      sx={{
        // TODO(andy): These are overridden in openevidence/src/global.css. Get rid of those.
        // Need to consolidate with the ArticleReferences accordion.
        border: 'none !important',
        borderTop: (theme) =>
          showTopBorder
            ? `1px solid ${theme.palette.divider} !important`
            : 'none !important',
        borderBottom: (theme) =>
          `1px solid ${theme.palette.divider} !important`,
        borderRadius: '0 !important',
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        sx={{
          px: 0,
          py: 0.5,
          '& .MuiAccordionSummary-content': { alignItems: 'center', gap: 1 },
        }}
      >
        <CalculateIcon fontSize='small' sx={{ color: 'text.secondary' }} />
        <Typography fontWeight={500} fontSize='1.125rem'>
          Formula
        </Typography>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          pt: 0,
          px: 0,
          '& .__Latex__': {
            fontSize: 14,
          },
        }}
      >
        {formulaSectionElement}
      </AccordionDetails>
    </Accordion>
  );
};
