// DO NOT MODIFY THIS FILE. IT WAS GENERATED BY GPT-4. ANY CHANGES WILL BE OVERWRITTEN.
import { registerCalculator } from '../registry';
import { CalculatorOutputValue, CalculatorOutput } from '../types';

export const CreatinineClearanceCalculator = (values: {
  age?: number | null;
  sex?: 'female' | 'male' | null;
  weight?: number | null;
  height?: number | null;
  creatinine?: number | null;
}): CalculatorOutput => {
  const { age, sex, weight, height, creatinine } = values;

  if (age == null || sex == null || weight == null || creatinine == null) {
    return null;
  }

  const cockcroftGaultFormula = (
    newWeight: number,
    doRound: boolean = true
  ): number => {
    const returnVal =
      ((140 - age) * newWeight * (sex === 'female' ? 0.85 : 1.0)) /
      (72 * creatinine);
    return doRound ? Math.round(returnVal * 100) / 100 : returnVal;
  };

  const toReturn: CalculatorOutput = {
    cockcroft_gault: {
      prefix: 'Cockcroft-Gault',
      value: cockcroftGaultFormula(weight),
      suffix: 'mL/min',
    },
  };

  if (height == null) {
    return toReturn;
  }

  const bmi = weight / (height / 100) ** 2;
  const heightInches = height / 2.54;
  const ibw = (sex === 'male' ? 50 : 45.5) + 2.3 * (heightInches - 60);
  const abw = ibw + 0.4 * (weight - ibw);

  if (18.5 < bmi && bmi < 25) {
    toReturn['adjusted_creatinine_clearance'] = {
      prefix: 'Adjusted Creatinine Clearance',
      value: cockcroftGaultFormula(ibw),
      suffix: 'mL/min',
    };
  } else if (bmi >= 25) {
    toReturn['adjusted_creatinine_clearance'] = {
      prefix: 'Adjusted Creatinine Clearance',
      value: cockcroftGaultFormula(abw),
      suffix: 'mL/min',
    };
  }

  return toReturn;
};

registerCalculator(
  'creatinine-clearance',
  CreatinineClearanceCalculator,
  'Creatinine Clearance'
);
