// useCalculatorDefinition.ts

import { useState, useEffect } from 'react';
import yaml from 'js-yaml';
import type { CalculatorDefinition, CalculatorValues } from './types';

interface UseCalculatorDefinitionResult {
  calculatorDefinition?: CalculatorDefinition;
  defaultValues?: CalculatorValues;
}

/** Loads the full specification of the calculator from the server. */
export function useCalculatorDefinition(
  slug?: string,
  apiBaseUrl: string = ''
): UseCalculatorDefinitionResult {
  const [calculatorDefinition, setCalculatorDefinition] = useState<
    CalculatorDefinition | undefined
  >();
  const [defaultValues, setDefaultValues] = useState<
    CalculatorValues | undefined
  >();

  useEffect(() => {
    const loadCalculatorDefinition = async () => {
      if (!slug) {
        return {};
      }
      try {
        // Fetch the YAML file from the public directory
        const response = await fetch(
          `${apiBaseUrl}/calculator_definitions/${encodeURIComponent(
            slug
          )}.yaml`
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const yamlText = await response.text();
        // Parse the YAML content
        const data = yaml.load(yamlText) as CalculatorDefinition;

        setCalculatorDefinition(data);
        setDefaultValues(
          data.parameters.reduce((acc, param) => {
            acc[param.name] =
              param.value ??
              (param.type === 'ChoicesParameter' ? param.default_value : null);
            return acc;
          }, {} as CalculatorValues)
        );
      } catch (error) {
        console.error('Error loading calculator data:', error);
      }
    };

    loadCalculatorDefinition();
  }, [slug]);

  return {
    calculatorDefinition,
    defaultValues,
  };
}
