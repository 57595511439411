// DO NOT MODIFY THIS FILE. IT WAS GENERATED BY GPT-4. ANY CHANGES WILL BE OVERWRITTEN.
import { registerCalculator } from '../registry';
import { CalculatorOutputValue, CalculatorOutput } from '../types';

export const HasBledCalculator = (values: {
  elderly?: boolean;
  hypertension?: boolean;
  renal_disease?: boolean;
  liver_disease?: boolean;
  stroke_history?: boolean;
  bleeding_predisposition?: boolean;
  medication_predisposing_to_bleeding?: boolean;
  unstable_inr?: boolean;
  high_alcohol_use?: boolean;
}): CalculatorOutput => {
  const {
    elderly = false,
    hypertension = false,
    renal_disease = false,
    liver_disease = false,
    stroke_history = false,
    bleeding_predisposition = false,
    medication_predisposing_to_bleeding = false,
    unstable_inr = false,
    high_alcohol_use = false,
  } = values;

  if (
    elderly === null ||
    elderly === undefined ||
    hypertension === null ||
    hypertension === undefined ||
    renal_disease === null ||
    renal_disease === undefined ||
    liver_disease === null ||
    liver_disease === undefined ||
    stroke_history === null ||
    stroke_history === undefined ||
    bleeding_predisposition === null ||
    bleeding_predisposition === undefined ||
    medication_predisposing_to_bleeding === null ||
    medication_predisposing_to_bleeding === undefined ||
    unstable_inr === null ||
    unstable_inr === undefined ||
    high_alcohol_use === null ||
    high_alcohol_use === undefined
  ) {
    return null;
  }

  let score = 0;
  if (elderly) score += 1;
  if (hypertension) score += 1;
  if (renal_disease) score += 1;
  if (liver_disease) score += 1;
  if (stroke_history) score += 1;
  if (bleeding_predisposition) score += 1;
  if (medication_predisposing_to_bleeding) score += 1;
  if (unstable_inr) score += 1;
  if (high_alcohol_use) score += 1;

  return {
    has_bled: {
      prefix: 'HAS-BLED Score',
      value: score,
    },
  };
};

registerCalculator('hasbled', HasBledCalculator, 'HAS-BLED Score');
