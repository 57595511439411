// DO NOT MODIFY THIS FILE. IT WAS GENERATED BY GPT-4. ANY CHANGES WILL BE OVERWRITTEN.
import { registerCalculator } from '../registry';
import { CalculatorOutputValue, CalculatorOutput } from '../types';

export const HEARTScoreCalculator = (values: {
  history?: number;
  ecg?: number;
  age?: number;
  risk_factors?: number;
  initial_troponin?: number;
}): CalculatorOutput => {
  const {
    history = 0,
    ecg = 0,
    age = 0,
    risk_factors = 0,
    initial_troponin = 0,
  } = values;

  if (
    history === null ||
    history === undefined ||
    ecg === null ||
    ecg === undefined ||
    age === null ||
    age === undefined ||
    risk_factors === null ||
    risk_factors === undefined ||
    initial_troponin === null ||
    initial_troponin === undefined
  ) {
    return null;
  }

  const score = history + ecg + age + risk_factors + initial_troponin;

  return {
    heart: {
      prefix: 'HEART Score',
      value: score,
    },
  };
};

registerCalculator('heart-score', HEARTScoreCalculator, 'HEART Score');
