// DO NOT MODIFY THIS FILE. IT WAS GENERATED BY GPT-4. ANY CHANGES WILL BE OVERWRITTEN.
import { registerCalculator } from '../registry';
import { CalculatorOutputValue, CalculatorOutput } from '../types';

export const ChildPughCalculator = (values: {
  bilirubin?: '<2 mg/dL' | '2-3 mg/dL' | '>3 mg/dL';
  albumin?: '<2.8 g/dL' | '2.8-3.5 g/dL' | '>3.5 g/dL';
  INR?: '<1.7' | '1.7-2.3' | '>2.3';
  ascites?: 'none' | 'mild' | 'moderate';
  encephalopathy?: 'none' | 'grade_1_2' | 'grade_3_4';
}): CalculatorOutput => {
  const { bilirubin, albumin, INR, ascites, encephalopathy } = values;

  if (
    bilirubin == null ||
    albumin == null ||
    INR == null ||
    ascites == null ||
    encephalopathy == null
  ) {
    return null;
  }

  let score = 0;
  score += { '<2 mg/dL': 1, '2-3 mg/dL': 2, '>3 mg/dL': 3 }[bilirubin] || 0;
  score += { '<2.8 g/dL': 3, '2.8-3.5 g/dL': 2, '>3.5 g/dL': 1 }[albumin] || 0;
  score += { '<1.7': 1, '1.7-2.3': 2, '>2.3': 3 }[INR] || 0;
  score += { none: 1, mild: 2, moderate: 3 }[ascites] || 0;
  score += { none: 1, grade_1_2: 2, grade_3_4: 3 }[encephalopathy] || 0;

  return {
    child_pugh: {
      prefix: 'Child-Pugh Score',
      value: score,
    },
  };
};

registerCalculator('child-pugh', ChildPughCalculator, 'Child-Pugh Score');
