import { Link, Stack, Typography } from '@mui/material';
import { CalculatorInfoMessage } from './CalculatorInfoMessage';
import { CalculatorHeading } from './CalculatorHelpers/CalculatorHeading';
import { ArticleSection } from '../../article/src';
import { CalculatorCard } from './CalculatorCard';

interface UnsupportedCalculatorMessageProps {
  sectionData: ArticleSection;
}

export function UnsupportedCalculatorMessage({
  sectionData,
}: UnsupportedCalculatorMessageProps) {
  if (!sectionData.metadata) {
    return null;
  }

  return (
    <CalculatorCard sx={{ mt: 2, mb: 3 }}>
      <Stack spacing={1}>
        {sectionData.metadata.calculator_name && (
          <CalculatorHeading>
            {sectionData.metadata.calculator_name}
          </CalculatorHeading>
        )}
        <Typography variant='body2'>
          Interactive calculation isn't supported yet for this clinical score.
          We have received your request and are working to expand our support.
          Thank you!
        </Typography>
        {/* TODO(andy): This isn't the best cuz it double-renders the Dialog (with CalculatorCard). It's ok for now. */}
        <CalculatorInfoMessage
          renderLabel={({ onClick }) => (
            <Link
              component='button'
              onClick={onClick}
              sx={{
                alignSelf: 'flex-start',
                color: 'text.secondary',
                fontFamily: 'inherit',
                fontSize: '0.875rem',
                textDecorationColor: 'inherit',
                '&:hover': { cursor: 'pointer' },
              }}
            >
              <Typography variant='body2'>Learn more</Typography>
            </Link>
          )}
        />
      </Stack>
    </CalculatorCard>
  );
}
