// DO NOT MODIFY THIS FILE. IT WAS GENERATED BY GPT-4. ANY CHANGES WILL BE OVERWRITTEN.
import { registerCalculator } from '../registry';
import { CalculatorOutputValue, CalculatorOutput } from '../types';

export const GlasgowComaScaleCalculator = (values: {
  eye: number | null | undefined;
  verbal: number | null | undefined;
  motor: number | null | undefined;
}): CalculatorOutput => {
  const { eye, verbal, motor } = values;

  if (
    eye === null ||
    eye === undefined ||
    verbal === null ||
    verbal === undefined ||
    motor === null ||
    motor === undefined
  ) {
    return null;
  }

  if (eye === 9 || verbal === 9 || motor === 9) {
    return null;
  }

  return {
    glasgow_coma_score: {
      prefix: 'Glasgow Coma Score',
      value: eye + verbal + motor,
    },
  };
};

registerCalculator(
  'glasgow-coma-scale',
  GlasgowComaScaleCalculator,
  'Glasgow Coma Scale'
);
