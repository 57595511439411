// DO NOT MODIFY THIS FILE. IT WAS GENERATED BY GPT-4. ANY CHANGES WILL BE OVERWRITTEN.
import { registerCalculator } from '../registry';
import { CalculatorOutputValue, CalculatorOutput } from '../types';

export const CorticosteroidEquivalencyConverter = (values: {
  current_steroid?:
    | 'betamethasone'
    | 'cortisone'
    | 'dexamethasone'
    | 'hydrocortisone'
    | 'methylprednisolone'
    | 'prednisolone'
    | 'prednisone'
    | 'triamcinolone';
  current_dose?: number;
  target_steroid?:
    | 'betamethasone'
    | 'cortisone'
    | 'dexamethasone'
    | 'hydrocortisone'
    | 'methylprednisolone'
    | 'prednisolone'
    | 'prednisone'
    | 'triamcinolone';
}): CalculatorOutput => {
  const { current_steroid, current_dose, target_steroid } = values;

  if (
    current_steroid == null ||
    current_dose == null ||
    target_steroid == null
  ) {
    return null;
  }

  const drug_dosages: { [key: string]: number } = {
    betamethasone: 0.75,
    cortisone: 25,
    dexamethasone: 0.75,
    hydrocortisone: 20,
    methylprednisolone: 4,
    prednisolone: 5,
    prednisone: 5,
    triamcinolone: 4,
  };

  const value =
    (current_dose / drug_dosages[current_steroid]) *
    drug_dosages[target_steroid];
  const rounded_value = Math.round(value * 100) / 100;

  return {
    steroid_conv: {
      prefix: 'Equivalent Dose',
      value: rounded_value,
      suffix: 'mg',
    },
  };
};

registerCalculator(
  'corticosteroid-equivalency',
  CorticosteroidEquivalencyConverter,
  'Corticosteroid Equivalency Converter'
);
