// DO NOT MODIFY THIS FILE. IT WAS GENERATED BY GPT-4. ANY CHANGES WILL BE OVERWRITTEN.
import { registerCalculator } from '../registry';
import { CalculatorOutputValue, CalculatorOutput } from '../types';

export const KawaskiCalculator = (values: {
  fever_duration?: boolean;
  extremity_changes?: number;
  polymorphous_exanthem?: boolean;
  conjunctival_injection?: boolean;
  oral_cavity_changes?: boolean;
  cervical_lymphadenopathy?: boolean;
  coronary_artery_disease?: boolean;
}): CalculatorOutput => {
  const {
    fever_duration = false,
    extremity_changes = 0,
    polymorphous_exanthem = false,
    conjunctival_injection = false,
    oral_cavity_changes = false,
    cervical_lymphadenopathy = false,
    coronary_artery_disease = false,
  } = values;

  if (
    fever_duration === null ||
    fever_duration === undefined ||
    extremity_changes === null ||
    extremity_changes === undefined ||
    polymorphous_exanthem === null ||
    polymorphous_exanthem === undefined ||
    conjunctival_injection === null ||
    conjunctival_injection === undefined ||
    oral_cavity_changes === null ||
    oral_cavity_changes === undefined ||
    cervical_lymphadenopathy === null ||
    cervical_lymphadenopathy === undefined ||
    coronary_artery_disease === null ||
    coronary_artery_disease === undefined
  ) {
    return null;
  }

  // Calculate the number of principal features present
  const principal_features = [
    extremity_changes > 0,
    polymorphous_exanthem,
    conjunctival_injection,
    oral_cavity_changes,
    cervical_lymphadenopathy,
  ].filter(Boolean).length;

  // Scenario 1: Fever for ≥5 days, but coronary artery disease is present
  // "Patients with fever for ≥5 days and <4 principal features can be diagnosed as having Kawasaki disease when coronary artery disease is detected by 2D echocardiography (2DE) or coronary angiography."
  let diagnosis = 'Negative';
  if (fever_duration && coronary_artery_disease) {
    diagnosis = 'Positive';
  }

  // Scenario 2: ≥4 principal features, fever for ≥5 days
  if (principal_features >= 4 && fever_duration) {
    diagnosis = 'Positive';
  }

  // If none of the above, return "negative"
  return {
    kawasaki_diagnosis: {
      value: diagnosis,
    },
  };
};

registerCalculator(
  'kawasaki',
  KawaskiCalculator,
  'Kawasaki Disease Diagnostic Criteria'
);
