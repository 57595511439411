// DO NOT MODIFY THIS FILE. IT WAS GENERATED BY GPT-4. ANY CHANGES WILL BE OVERWRITTEN.
import { registerCalculator } from '../registry';
import { CalculatorOutputValue, CalculatorOutput } from '../types';

export const CIWAArCalculator = (values: {
  nausea_vomiting?: number;
  tremor?: number;
  paroxysmal_sweats?: number;
  anxiety?: number;
  agitation?: number;
  tactile_disturbances?: number;
  auditory_disturbances?: number;
  visual_disturbances?: number;
  headache?: number;
  orientation?: number;
}): CalculatorOutput => {
  const {
    nausea_vomiting = 0,
    tremor = 0,
    paroxysmal_sweats = 0,
    anxiety = 0,
    agitation = 0,
    tactile_disturbances = 0,
    auditory_disturbances = 0,
    visual_disturbances = 0,
    headache = 0,
    orientation = 0,
  } = values;

  if (
    nausea_vomiting === null ||
    nausea_vomiting === undefined ||
    tremor === null ||
    tremor === undefined ||
    paroxysmal_sweats === null ||
    paroxysmal_sweats === undefined ||
    anxiety === null ||
    anxiety === undefined ||
    agitation === null ||
    agitation === undefined ||
    tactile_disturbances === null ||
    tactile_disturbances === undefined ||
    auditory_disturbances === null ||
    auditory_disturbances === undefined ||
    visual_disturbances === null ||
    visual_disturbances === undefined ||
    headache === null ||
    headache === undefined ||
    orientation === null ||
    orientation === undefined
  ) {
    return null;
  }

  const score =
    nausea_vomiting +
    tremor +
    paroxysmal_sweats +
    anxiety +
    agitation +
    tactile_disturbances +
    auditory_disturbances +
    visual_disturbances +
    headache +
    orientation;

  return {
    ciwa: {
      prefix: 'CIWA-Ar Score',
      value: score,
    },
  };
};

registerCalculator('ciwa-ar', CIWAArCalculator, 'CIWA-Ar Score');
