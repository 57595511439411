// Use Webpack's require.context to load all calculator modules
const calculatorsContext = require.context(
  './calculator_registry',
  false,
  /\.tsx$/
);

export function loadCalculators() {
  calculatorsContext.keys().forEach((filename) => {
    calculatorsContext(filename);
  });
}
