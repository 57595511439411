// DO NOT MODIFY THIS FILE. IT WAS GENERATED BY GPT-4. ANY CHANGES WILL BE OVERWRITTEN.
import { registerCalculator } from '../registry';
import { CalculatorOutputValue, CalculatorOutput } from '../types';

export const FickCardiacOutputCalculator = (values: {
  age?: '<70' | '>=70';
  weight?: number;
  height?: number;
  heart_rate?: number;
  saO2?: number;
  svO2?: number;
  hemoglobin?: number;
}): CalculatorOutput => {
  const { age, weight, height, heart_rate, saO2, svO2, hemoglobin } = values;

  if (
    age == null ||
    weight == null ||
    height == null ||
    heart_rate == null ||
    saO2 == null ||
    svO2 == null ||
    hemoglobin == null
  ) {
    return null;
  }

  // Calculate Body Surface Area (BSA) using the formula BSA = sqrt((height * weight) / 3600)
  const bsa = Math.sqrt((height * weight) / 3600);

  // Determine VO2 based on the patient's age
  const vo2 = age === '<70' ? 125 * bsa : 110 * bsa;

  if (saO2 - svO2 === 0) {
    return null;
  }

  // Calculate final cardiac output, cardiac index, and stroke volume
  const co = vo2 / ((saO2 - svO2) * hemoglobin * 13.4);
  const cardiac_index = co / bsa;
  const stroke_volume = co / heart_rate;

  const pct_co = co * 100;
  const pct_cardiac_index = cardiac_index * 100;
  const pct_stroke_volume = stroke_volume * 100000;

  const rounded_co = Math.round(pct_co * 100) / 100;
  const rounded_cardiac_index = Math.round(pct_cardiac_index * 100) / 100;
  const rounded_stroke_volume = Math.round(pct_stroke_volume * 100) / 100;

  return {
    cardiac_output: {
      prefix: 'Cardiac Output',
      value: rounded_co,
      suffix: 'L/min',
    },
    cardiac_index: {
      prefix: 'Cardiac Index',
      value: rounded_cardiac_index,
      suffix: 'L/min/m²',
    },
    stroke_volume: {
      prefix: 'Stroke Volume',
      value: rounded_stroke_volume,
      suffix: 'mL/beat',
    },
  };
};

registerCalculator(
  'fick_cardiac_output',
  FickCardiacOutputCalculator,
  'Fick Cardiac Output'
);
