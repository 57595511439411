// DO NOT MODIFY THIS FILE. IT WAS GENERATED BY GPT-4. ANY CHANGES WILL BE OVERWRITTEN.
import { registerCalculator } from '../registry';
import { CalculatorOutputValue, CalculatorOutput } from '../types';

export const CalciumCorrectionCalculator = (values: {
  calcium?: number | null;
  albumin?: number | null;
  normal_albumin?: number | null;
}): CalculatorOutput => {
  const { calcium, albumin, normal_albumin } = values;

  const defaultNormalAlbumin = 4.0;
  const effectiveNormalAlbumin = normal_albumin ?? defaultNormalAlbumin;

  if (calcium == null || albumin == null) {
    return null;
  }

  const correctedCalcium = 0.8 * (effectiveNormalAlbumin - albumin) + calcium;
  const roundedCorrectedCalcium = Math.round(correctedCalcium * 100) / 100;

  return {
    corrected_calcium: {
      prefix: 'Corrected Calcium',
      value: roundedCorrectedCalcium,
      suffix: 'mg/dL',
    },
  };
};

registerCalculator(
  'calcium_correction',
  CalciumCorrectionCalculator,
  'Calcium Correction for Hypoalbuminemia'
);
