// DO NOT MODIFY THIS FILE. IT WAS GENERATED BY GPT-4. ANY CHANGES WILL BE OVERWRITTEN.
import { registerCalculator } from '../registry';
import { CalculatorOutputValue, CalculatorOutput } from '../types';

export const FraminghamRiskScoreCalculator = (values: {
  age?: number | null;
  sex?: 'male' | 'female' | null;
  total_cholesterol?: number | null;
  hdl_cholesterol?: number | null;
  systolic_bp?: number | null;
  on_bp_medication?: boolean | null;
  smoker?: boolean | null;
  diabetes?: boolean | null;
}): CalculatorOutput => {
  const {
    age,
    sex,
    total_cholesterol,
    hdl_cholesterol,
    systolic_bp,
    on_bp_medication = false,
    smoker = false,
    diabetes = false,
  } = values;

  if (
    age == null ||
    sex == null ||
    total_cholesterol == null ||
    hdl_cholesterol == null ||
    systolic_bp == null ||
    on_bp_medication == null ||
    smoker == null ||
    diabetes == null
  ) {
    return null;
  }

  const coeff =
    sex === 'male'
      ? {
          age: 3.06117,
          total_cholesterol: 1.1237,
          hdl_cholesterol: -0.93263,
          systolic_bp_not_treated: 1.93303,
          systolic_bp_treated: 1.99881,
          smoker: 0.65451,
          diabetes: 0.57367,
          mean_ln_risk: -23.9802,
          baseline_risk: 0.88936,
        }
      : {
          age: 2.32888,
          total_cholesterol: 1.20904,
          hdl_cholesterol: -0.70833,
          systolic_bp_not_treated: 2.76157,
          systolic_bp_treated: 2.82263,
          smoker: 0.52873,
          diabetes: 0.69154,
          mean_ln_risk: -26.1931,
          baseline_risk: 0.95012,
        };

  const ln_age = Math.log(age);
  const ln_total_chol = Math.log(total_cholesterol);
  const ln_hdl_chol = Math.log(hdl_cholesterol);
  const ln_sys_bp = Math.log(systolic_bp);

  const ln_sys_bp_coeff = on_bp_medication
    ? coeff.systolic_bp_treated
    : coeff.systolic_bp_not_treated;

  const risk_score =
    coeff.age * ln_age +
    coeff.total_cholesterol * ln_total_chol +
    coeff.hdl_cholesterol * ln_hdl_chol +
    ln_sys_bp_coeff * ln_sys_bp +
    coeff.smoker * Number(smoker) +
    coeff.diabetes * Number(diabetes);

  const risk =
    1 -
    Math.pow(coeff.baseline_risk, Math.exp(risk_score + coeff.mean_ln_risk));
  const risk_pct = risk * 100;
  const rounded_risk_pct = Math.round(risk_pct * 100) / 100;

  return {
    framingham_risk: {
      prefix: '10-year Risk of Cardiovascular Disease',
      value: rounded_risk_pct,
      suffix: '%',
    },
  };
};

registerCalculator(
  'framingham_risk',
  FraminghamRiskScoreCalculator,
  'Framingham Risk Score for Coronary Heart Disease'
);
