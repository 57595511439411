// DO NOT MODIFY THIS FILE. IT WAS GENERATED BY GPT-4. ANY CHANGES WILL BE OVERWRITTEN.
import { registerCalculator } from '../registry';
import { CalculatorOutputValue, CalculatorOutput } from '../types';

export const GraceScoreCalculator = (values: {
  age?: number | null;
  heart_rate?: number | null;
  systolic_bp?: number | null;
  creatinine?: number | null;
  cardiac_arrest_at_admission?: boolean | null;
  st_segment_deviation?: boolean | null;
  elevated_cardiac_enzymes?: boolean | null;
  killip_class?: number | null;
}): CalculatorOutput => {
  const {
    age,
    heart_rate,
    systolic_bp,
    creatinine,
    cardiac_arrest_at_admission,
    st_segment_deviation,
    elevated_cardiac_enzymes,
    killip_class,
  } = values;

  if (
    age == null ||
    heart_rate == null ||
    systolic_bp == null ||
    creatinine == null ||
    cardiac_arrest_at_admission == null ||
    st_segment_deviation == null ||
    elevated_cardiac_enzymes == null ||
    killip_class == null
  ) {
    return null;
  }

  let age2 = 0;
  let sysbp2 = 0;
  let heart_rate2 = 0;
  let crt2 = 0;
  let killips = 0;

  if (age < 35) {
    return null;
  } else if (35 <= age && age < 45) {
    age2 = 0 + (age - 35) * 1.8;
  } else if (45 <= age && age < 55) {
    age2 = 18 + (age - 45) * 1.8;
  } else if (55 <= age && age < 65) {
    age2 = 36 + (age - 55) * 1.8;
  } else if (65 <= age && age < 75) {
    age2 = 54 + (age - 65) * 1.9;
  } else if (75 <= age && age < 85) {
    age2 = 73 + (age - 75) * 1.8;
  } else if (85 <= age && age < 90) {
    age2 = 91 + (age - 85) * 1.8;
  } else if (age >= 90) {
    age2 = 100;
  }

  if (0 <= heart_rate && heart_rate < 70) {
    heart_rate2 = 0;
  } else if (70 <= heart_rate && heart_rate < 80) {
    heart_rate2 = 0 + (heart_rate - 70) * 0.3;
  } else if (80 <= heart_rate && heart_rate < 90) {
    heart_rate2 = 3 + (heart_rate - 80) * 0.2;
  } else if (90 <= heart_rate && heart_rate < 100) {
    heart_rate2 = 5 + (heart_rate - 90) * 0.3;
  } else if (100 <= heart_rate && heart_rate < 110) {
    heart_rate2 = 8 + (heart_rate - 100) * 0.2;
  } else if (110 <= heart_rate && heart_rate < 150) {
    heart_rate2 = 10 + (heart_rate - 110) * 0.3;
  } else if (150 <= heart_rate && heart_rate < 200) {
    heart_rate2 = 22 + (heart_rate - 150) * 0.3;
  } else if (heart_rate >= 200) {
    heart_rate2 = 34;
  }

  if (0 <= systolic_bp && systolic_bp < 80) {
    sysbp2 = 40;
  } else if (80 <= systolic_bp && systolic_bp < 100) {
    sysbp2 = 40 - (systolic_bp - 80) * 0.3;
  } else if (100 <= systolic_bp && systolic_bp < 110) {
    sysbp2 = 34 - (systolic_bp - 100) * 0.3;
  } else if (110 <= systolic_bp && systolic_bp < 120) {
    sysbp2 = 31 - (systolic_bp - 110) * 0.4;
  } else if (120 <= systolic_bp && systolic_bp < 130) {
    sysbp2 = 27 - (systolic_bp - 120) * 0.3;
  } else if (130 <= systolic_bp && systolic_bp < 140) {
    sysbp2 = 24 - (systolic_bp - 130) * 0.3;
  } else if (140 <= systolic_bp && systolic_bp < 150) {
    sysbp2 = 20 - (systolic_bp - 140) * 0.4;
  } else if (150 <= systolic_bp && systolic_bp < 160) {
    sysbp2 = 17 - (systolic_bp - 150) * 0.3;
  } else if (160 <= systolic_bp && systolic_bp < 180) {
    sysbp2 = 14 - (systolic_bp - 160) * 0.3;
  } else if (180 <= systolic_bp && systolic_bp < 200) {
    sysbp2 = 8 - (systolic_bp - 180) * 0.4;
  } else if (systolic_bp >= 200) {
    sysbp2 = 0;
  }

  if (0.0 <= creatinine && creatinine < 0.2) {
    crt2 = 0 + (creatinine - 0) * (1 / 0.2);
  } else if (0.2 <= creatinine && creatinine < 0.4) {
    crt2 = 1 + (creatinine - 0.2) * (2 / 0.2);
  } else if (0.4 <= creatinine && creatinine < 0.6) {
    crt2 = 3 + (creatinine - 0.4) * (1 / 0.2);
  } else if (0.6 <= creatinine && creatinine < 0.8) {
    crt2 = 4 + (creatinine - 0.6) * (2 / 0.2);
  } else if (0.8 <= creatinine && creatinine < 1.0) {
    crt2 = 6 + (creatinine - 0.8) * (1 / 0.2);
  } else if (1.0 <= creatinine && creatinine < 1.2) {
    crt2 = 7 + (creatinine - 1.0) * (1 / 0.2);
  } else if (1.2 <= creatinine && creatinine < 1.4) {
    crt2 = 8 + (creatinine - 1.2) * (2 / 0.2);
  } else if (1.4 <= creatinine && creatinine < 1.6) {
    crt2 = 10 + (creatinine - 1.4) * (1 / 0.2);
  } else if (1.6 <= creatinine && creatinine < 1.8) {
    crt2 = 11 + (creatinine - 1.6) * (2 / 0.2);
  } else if (1.8 <= creatinine && creatinine < 2.0) {
    crt2 = 13 + (creatinine - 1.8) * (1 / 0.2);
  } else if (2.0 <= creatinine && creatinine < 3.0) {
    crt2 = 14 + (creatinine - 2.0) * (7 / 1);
  } else if (3.0 <= creatinine && creatinine < 4.0) {
    crt2 = 21 + (creatinine - 3.0) * (7 / 1);
  } else if (creatinine >= 4.0) {
    crt2 = 28;
  }

  if (killip_class < 0 || killip_class > 4) {
    return null;
  } else if (killip_class === 1) {
    killips = 0;
  } else if (killip_class === 2) {
    killips = 15;
  } else if (killip_class === 3) {
    killips = 29;
  } else if (killip_class === 4) {
    killips = 44;
  }

  const deatha6_pt =
    sysbp2 +
    heart_rate2 +
    age2 +
    crt2 +
    killips +
    30 * (cardiac_arrest_at_admission ? 1 : 0) +
    13 * (elevated_cardiac_enzymes ? 1 : 0) +
    17 * (st_segment_deviation ? 1 : 0);

  return {
    grace: {
      prefix: 'GRACE Score',
      value: deatha6_pt,
    },
  };
};

registerCalculator('grace', GraceScoreCalculator, 'GRACE Score');
