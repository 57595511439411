import { Box, Link, SxProps, Theme, Typography } from '@mui/material';
import { CalloutCitedDocument } from './types';
import { endWithPeriod } from '../../../utils';

export interface DocumentDetailsProps extends CalloutCitedDocument {
  sx?: SxProps<Theme>;
}

export const DocumentDetails = ({
  title,
  authors,
  publication_info,
  published_date_text,
  href,
  sx = {},
}: DocumentDetailsProps) => {
  // Lump most of the non-title citation details together.
  let extendedCitation = '';
  if (authors) {
    extendedCitation += authors;
  }
  if (publication_info) {
    extendedCitation += publication_info;
  }

  // Title stands alone because it will be hrefed and styled differently.
  // If we've got any citation info beyond the title, it needs to end with
  // a period to not look awkward.
  const titleForCitation = extendedCitation ? endWithPeriod(title) : title;

  return (
    <Box
      sx={[
        {
          dispay: 'flex',
          flexDirection: 'column',
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <Typography
        sx={{
          fontSize: 'inherit',
        }}
      >
        {href ? (
          <Link
            href={href}
            target='_blank'
            sx={{
              color: 'primary.main',
              fontWeight: 450,
            }}
          >
            {titleForCitation}
          </Link>
        ) : (
          titleForCitation
        )}
        {extendedCitation && ` ${extendedCitation}`}
      </Typography>
      {published_date_text && (
        <Typography
          sx={{
            fontSize: 'inherit',
            fontStyle: 'italic',
            color: 'grey.700',
          }}
        >
          {published_date_text}
        </Typography>
      )}
    </Box>
  );
};
