// DO NOT MODIFY THIS FILE. IT WAS GENERATED BY GPT-4. ANY CHANGES WILL BE OVERWRITTEN.
import { registerCalculator } from '../registry';
import { CalculatorOutputValue, CalculatorOutput } from '../types';

export const FourTScoreCalculator = (values: {
  thrombocytopenia?: number;
  timing_of_platelet_count_fall?: number;
  thrombosis_or_other_sequelae?: number;
  other_causes_for_thrombocytopenia?: number;
}): CalculatorOutput => {
  const {
    thrombocytopenia = 0,
    timing_of_platelet_count_fall = 0,
    thrombosis_or_other_sequelae = 0,
    other_causes_for_thrombocytopenia = 0,
  } = values;

  if (
    thrombocytopenia === null ||
    thrombocytopenia === undefined ||
    timing_of_platelet_count_fall === null ||
    timing_of_platelet_count_fall === undefined ||
    thrombosis_or_other_sequelae === null ||
    thrombosis_or_other_sequelae === undefined ||
    other_causes_for_thrombocytopenia === null ||
    other_causes_for_thrombocytopenia === undefined
  ) {
    return null;
  }

  const score =
    thrombocytopenia +
    timing_of_platelet_count_fall +
    thrombosis_or_other_sequelae +
    other_causes_for_thrombocytopenia;

  return {
    '4t_hit': {
      prefix: '4T Score',
      value: score,
    },
  };
};

registerCalculator(
  '4ts-score-hit',
  FourTScoreCalculator,
  '4Ts Score for Heparin-Induced Thrombocytopenia (HIT)'
);
