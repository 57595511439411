// DO NOT MODIFY THIS FILE. IT WAS GENERATED BY GPT-4. ANY CHANGES WILL BE OVERWRITTEN.
import { registerCalculator } from '../registry';
import { CalculatorOutputValue, CalculatorOutput } from '../types';

export const ABCD2Calculator = (values: {
  age?: boolean | null;
  blood_pressure?: boolean | null;
  clinical_features?:
    | 'unilateral_weakness'
    | 'speech_disturbance'
    | 'other'
    | null;
  duration_of_symptoms?: '<10' | '10-59' | '60+' | null;
  diabetes?: boolean | null;
}): CalculatorOutput => {
  const {
    age,
    blood_pressure,
    clinical_features,
    duration_of_symptoms,
    diabetes,
  } = values;

  if (
    age === null ||
    age === undefined ||
    blood_pressure === null ||
    blood_pressure === undefined ||
    clinical_features === null ||
    clinical_features === undefined ||
    duration_of_symptoms === null ||
    duration_of_symptoms === undefined ||
    diabetes === null ||
    diabetes === undefined
  ) {
    return null;
  }

  let score = 0;
  score += age ? 1 : 0;
  score += blood_pressure ? 1 : 0;

  if (clinical_features === 'unilateral_weakness') {
    score += 2;
  } else if (clinical_features === 'speech_disturbance') {
    score += 1;
  }

  if (duration_of_symptoms === '10-59') {
    score += 1;
  } else if (duration_of_symptoms === '60+') {
    score += 2;
  }

  score += diabetes ? 1 : 0;

  return {
    abcd2_score: {
      prefix: 'ABCD2 Score',
      value: score,
    },
  };
};

registerCalculator('abcd2', ABCD2Calculator, 'ABCD2 Score');
