import { Card } from '@mui/material';
import { SxProps } from '@mui/material/styles';
import type { ReactNode } from 'react';
import { CalculatorInfoMessage } from './CalculatorInfoMessage';

/** Displays a card wrapper around Calculator content, with a branded label in the lower-right corner. */
export function CalculatorCard({
  children,
  sx,
}: {
  children?: ReactNode;
  sx?: SxProps;
}) {
  return (
    <Card
      variant='outlined'
      sx={{
        borderRadius: 2,
        padding: 2,
        backgroundColor: '#fff',
        position: 'relative',
        overflow: 'visible',
        ...sx,
      }}
    >
      {children}
      <CalculatorInfoMessage
        sx={{
          position: 'absolute',
          bottom: 0,
          right: '16px',
          transform: 'translateY(50%)',
        }}
      />
    </Card>
  );
}
