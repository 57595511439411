import { Box, Divider, Grow } from '@mui/material';
import { SxProps } from '@mui/material/styles';
import { useArticleContext } from '../../../contexts';
import { CalloutCitedDocument } from './types';
import { Header } from './Header';
import { Footer } from './Footer';
import { ActionButtonProps } from './ActionButton';

const DEFAULT_BORDER_RADIUS = '8px';
const DEFAULT_HEADER_BACKGROUND_COLOR = '#dce4ef'; // light blue
const DEFAULT_HEADER_TEXT_COLOR = '#205493'; // dark blue
const DEFAULT_HEADER_FONT_SIZE = '1.125rem';
const DEFAULT_FOOTER_FONT_SIZE = '0.875rem';
const DEFAULT_TRANSITION_TIMEOUT_MS = 750;

export interface CalloutProps {
  // header content
  title: string;
  icon: React.ReactNode;
  actions?: ActionButtonProps[];

  // body content
  children: React.ReactNode;

  // footer content
  attribution?: string;
  documents?: CalloutCitedDocument[];
  documentDetailsListPreviewText?:
    | string
    | ((documents: CalloutCitedDocument[]) => string);

  // style overrides
  sx?: SxProps;
  bodySx?: SxProps;
  headerSx?: SxProps;
  footerSx?: SxProps;
  transitionTimeoutMs?: number;
}

export const Callout = ({
  title,
  icon,
  actions,
  children,
  attribution,
  documents,
  documentDetailsListPreviewText,
  sx = {},
  bodySx = {},
  headerSx = {},
  footerSx = {},
  transitionTimeoutMs = DEFAULT_TRANSITION_TIMEOUT_MS,
}: CalloutProps) => {
  const { isTyping } = useArticleContext();

  return (
    <Grow
      in
      timeout={{ enter: transitionTimeoutMs, exit: transitionTimeoutMs }}
      appear={isTyping}
    >
      <Box
        sx={[
          {
            display: 'flex',
            flexDirection: 'column',
            marginTop: 1,
            marginBottom: 3,
            backgroundColor: 'grey.50',
            borderRadius: DEFAULT_BORDER_RADIUS,
            border: 1,
            borderColor: 'grey.300',
          },
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
      >
        <Header
          title={title}
          icon={icon}
          actions={actions}
          sx={[
            {
              color: DEFAULT_HEADER_TEXT_COLOR,
              backgroundColor: DEFAULT_HEADER_BACKGROUND_COLOR,
              fontSize: DEFAULT_HEADER_FONT_SIZE,
              borderRadius: `${DEFAULT_BORDER_RADIUS} ${DEFAULT_BORDER_RADIUS} 0 0`,
            },
            ...(Array.isArray(headerSx) ? headerSx : [headerSx]),
          ]}
        />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            padding: 2,
            gap: 2,
          }}
        >
          <Box sx={bodySx}>{children}</Box>
          {attribution && ( // only show footer if attribution is provided
            <>
              <Divider />
              <Footer
                attribution={attribution}
                documents={documents}
                documentDetailsListPreviewText={documentDetailsListPreviewText}
                sx={[
                  {
                    fontSize: DEFAULT_FOOTER_FONT_SIZE,
                  },
                  ...(Array.isArray(footerSx) ? footerSx : [footerSx]),
                ]}
              />
            </>
          )}
        </Box>
      </Box>
    </Grow>
  );
};
