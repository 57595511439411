// DO NOT MODIFY THIS FILE. IT WAS GENERATED BY GPT-4. ANY CHANGES WILL BE OVERWRITTEN.
import { registerCalculator } from '../registry';
import { CalculatorOutputValue, CalculatorOutput } from '../types';

export const ModifiedSgarbossaCalculator = (values: {
  concordant_st_elevation?: boolean;
  concordant_st_depression?: boolean;
  excessive_discordant_st_elevation?: boolean;
}): CalculatorOutput => {
  const {
    concordant_st_elevation = false,
    concordant_st_depression = false,
    excessive_discordant_st_elevation = false,
  } = values;

  const isPositive =
    concordant_st_elevation ||
    concordant_st_depression ||
    excessive_discordant_st_elevation;

  return {
    modified_sgarbossa_criteria: {
      prefix: 'Modified Sgarbossa Criteria',
      value: isPositive ? 'Positive' : 'Negative',
    },
  };
};

registerCalculator(
  'modifiedsgarbossa',
  ModifiedSgarbossaCalculator,
  'Modified Sgarbossa Criteria for MI in LBBB'
);
