// DO NOT MODIFY THIS FILE. IT WAS GENERATED BY GPT-4. ANY CHANGES WILL BE OVERWRITTEN.
import { registerCalculator } from '../registry';
import { CalculatorOutputValue, CalculatorOutput } from '../types';

export const PaduaForVTECalculator = (values: {
  elderly?: boolean;
  obesity?: boolean;
  active_cancer?: boolean;
  previous_vte?: boolean;
  immobilization?: boolean;
  heart_or_respiratory_failure?: boolean;
  acute_myocardial_infarction_or_ischemic_stroke?: boolean;
  acute_infection_or_rheumatologic_disorders?: boolean;
  existing_thrombophilic_condition?: boolean;
  hormone_therapy?: boolean;
  recent_trauma_or_surgery?: boolean;
}): CalculatorOutput => {
  const {
    elderly = false,
    obesity = false,
    active_cancer = false,
    previous_vte = false,
    immobilization = false,
    heart_or_respiratory_failure = false,
    acute_myocardial_infarction_or_ischemic_stroke = false,
    acute_infection_or_rheumatologic_disorders = false,
    existing_thrombophilic_condition = false,
    hormone_therapy = false,
    recent_trauma_or_surgery = false,
  } = values;

  if (
    elderly === null ||
    elderly === undefined ||
    obesity === null ||
    obesity === undefined ||
    active_cancer === null ||
    active_cancer === undefined ||
    previous_vte === null ||
    previous_vte === undefined ||
    immobilization === null ||
    immobilization === undefined ||
    heart_or_respiratory_failure === null ||
    heart_or_respiratory_failure === undefined ||
    acute_myocardial_infarction_or_ischemic_stroke === null ||
    acute_myocardial_infarction_or_ischemic_stroke === undefined ||
    acute_infection_or_rheumatologic_disorders === null ||
    acute_infection_or_rheumatologic_disorders === undefined ||
    existing_thrombophilic_condition === null ||
    existing_thrombophilic_condition === undefined ||
    hormone_therapy === null ||
    hormone_therapy === undefined ||
    recent_trauma_or_surgery === null ||
    recent_trauma_or_surgery === undefined
  ) {
    return null;
  }

  let score = 0;
  score += elderly ? 1 : 0;
  score += obesity ? 1 : 0;
  score += heart_or_respiratory_failure ? 1 : 0;
  score += acute_myocardial_infarction_or_ischemic_stroke ? 1 : 0;
  score += acute_infection_or_rheumatologic_disorders ? 1 : 0;
  score += hormone_therapy ? 1 : 0;

  // 2 points
  score += recent_trauma_or_surgery ? 2 : 0;

  // 3 points
  score += active_cancer ? 3 : 0;
  score += previous_vte ? 3 : 0;
  score += immobilization ? 3 : 0;
  score += existing_thrombophilic_condition ? 3 : 0;

  return {
    padua_prediction_score: {
      prefix: 'Padua Prediction Score',
      value: score,
    },
  };
};

registerCalculator(
  'padua-for-vte',
  PaduaForVTECalculator,
  'Padua Prediction for VTE Risk'
);
