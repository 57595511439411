// DO NOT MODIFY THIS FILE. IT WAS GENERATED BY GPT-4. ANY CHANGES WILL BE OVERWRITTEN.
import { registerCalculator } from '../registry';
import { CalculatorOutputValue, CalculatorOutput } from '../types';

export const SerumOsmolalityOsmolarityCalculator = (values: {
  sodium?: number | null;
  bun?: number | null;
  glucose?: number | null;
  alcohol?: number;
  osmolality?: number | null;
}): CalculatorOutput => {
  const { sodium, bun, glucose, alcohol = 0.0, osmolality } = values;

  if (sodium == null || bun == null || glucose == null) {
    return null;
  }

  // Calculate the serum osmolality --- Return all 3:

  // Traditional equation:
  // Serum osmolality, US units = (2 × Na + (BUN / 2.8) + (glucose / 18) + (ethanol / 4.6)

  // Purssell et al equation:
  // Serum osmolality, US units = (2 × Na + (BUN / 2.8) + (glucose / 18) + (ethanol / 3.7)

  // Note: the range displayed in the calculator uses the equations above.
  // Osmolal gap equation:
  // Osmolal gap = measured serum osm - calculated serum osm
  // Note: the osmolal gap displayed in the calculator uses the Pursell et al equation.

  const traditional_osmolality =
    2 * sodium + bun / 2.8 + glucose / 18 + alcohol / 4.6;
  const pursell_osmolality =
    2 * sodium + bun / 2.8 + glucose / 18 + alcohol / 3.7;

  const rounded_traditional_osmolality =
    Math.round(traditional_osmolality * 100) / 100;
  const rounded_pursell_osmolality = Math.round(pursell_osmolality * 100) / 100;

  const to_return: CalculatorOutput = {
    traditional_osmolality: {
      prefix: 'Traditional Serum Osmolality',
      value: rounded_traditional_osmolality,
      suffix: 'mOsm/kg',
    },
    pursell_osmolality: {
      prefix: 'Purssell Serum Osmolality',
      value: rounded_pursell_osmolality,
      suffix: 'mOsm/kg',
    },
  };

  if (osmolality != null) {
    const osmolal_gap = osmolality - pursell_osmolality;
    const rounded_osmolal_gap = Math.round(osmolal_gap * 100) / 100;
    to_return['osmolal_gap'] = {
      prefix: 'Osmolal Gap',
      value: rounded_osmolal_gap,
      suffix: 'mOsm/kg',
    };
  }

  return to_return;
};

registerCalculator(
  'serum-osmolality-osmolarity',
  SerumOsmolalityOsmolarityCalculator,
  'Serum Osmolality/Osmolarity'
);
