// DO NOT MODIFY THIS FILE. IT WAS GENERATED BY GPT-4. ANY CHANGES WILL BE OVERWRITTEN.
import { registerCalculator } from '../registry';
import { CalculatorOutputValue, CalculatorOutput } from '../types';

export const PediatricSIRSCalculator = (values: {
  temperature?: boolean;
  tachy_or_bradycardia?: boolean;
  tachypnea?: boolean;
  leukocytosis_or_leukopenia?: boolean;
  infection?: boolean;
  cardiovascular_issues?: boolean;
  cardiovascular_organ_dysfunction_with_fluid?: boolean;
}): CalculatorOutput => {
  const {
    temperature,
    tachy_or_bradycardia,
    tachypnea,
    leukocytosis_or_leukopenia,
    infection,
    cardiovascular_issues,
    cardiovascular_organ_dysfunction_with_fluid,
  } = values;

  if (
    temperature === null ||
    temperature === undefined ||
    tachy_or_bradycardia === null ||
    tachy_or_bradycardia === undefined ||
    tachypnea === null ||
    tachypnea === undefined ||
    leukocytosis_or_leukopenia === null ||
    leukocytosis_or_leukopenia === undefined ||
    infection === null ||
    infection === undefined ||
    cardiovascular_issues === null ||
    cardiovascular_issues === undefined ||
    cardiovascular_organ_dysfunction_with_fluid === null ||
    cardiovascular_organ_dysfunction_with_fluid === undefined
  ) {
    return null;
  }

  let sirs_active = false;
  if (
    Number(temperature) +
      Number(tachy_or_bradycardia) +
      Number(tachypnea) +
      Number(leukocytosis_or_leukopenia) >=
    2
  ) {
    sirs_active = Boolean(temperature || leukocytosis_or_leukopenia);
  }

  const sepsis_active = sirs_active && Boolean(infection);
  const severe_sepsis_active = sirs_active && Boolean(cardiovascular_issues);
  const septic_shock_active =
    sirs_active && Boolean(cardiovascular_organ_dysfunction_with_fluid);

  let message = '';
  if (septic_shock_active) {
    message = 'The patient meets the criteria for Septic Shock.';
  } else if (severe_sepsis_active) {
    message = 'The patient meets the criteria for Severe Sepsis.';
  } else if (sepsis_active) {
    message = 'The patient meets the criteria for Sepsis.';
  } else if (sirs_active) {
    message =
      'The patient meets the criteria for Systemic Inflammatory Response Syndrome (SIRS).';
  } else {
    message =
      'The patient does not meet the criteria for SIRS, Sepsis, or Septic Shock.';
  }

  return {
    pediatric_sirs: {
      value: message,
    },
  };
};

registerCalculator(
  'pediatric-sirs-sepsis-septic-shock',
  PediatricSIRSCalculator,
  'Pediatric SIRS, Sepsis, and Septic Shock'
);
