// DO NOT MODIFY THIS FILE. IT WAS GENERATED BY GPT-4. ANY CHANGES WILL BE OVERWRITTEN.
import { registerCalculator } from '../registry';
import { CalculatorOutputValue, CalculatorOutput } from '../types';

export const SOFACalculator = (values: {
  PaO2?: number | null;
  FiO2?: number | null;
  on_ventilation?: boolean | null;
  platelets?: number | null;
  bilirubin?: number | null;
  glascow_coma_scale?: number | null;
  map_or_vasoactive?: number | null;
  creatinine?: number | null;
}): CalculatorOutput => {
  const {
    PaO2,
    FiO2,
    on_ventilation = false,
    platelets = 0,
    bilirubin = 0,
    glascow_coma_scale = 0,
    map_or_vasoactive = 0,
    creatinine = 0,
  } = values;

  if (
    PaO2 == null ||
    FiO2 == null ||
    on_ventilation == null ||
    platelets == null ||
    bilirubin == null ||
    glascow_coma_scale == null ||
    map_or_vasoactive == null ||
    creatinine == null
  ) {
    return null;
  }

  let score = 0;
  const pao2_fio2_ratio = PaO2 / (FiO2 / 100);

  // PaO2/FiO2*, mmHg
  if (pao2_fio2_ratio >= 400) {
    score += 0;
  } else if (pao2_fio2_ratio >= 300) {
    score += 1;
  } else if (pao2_fio2_ratio >= 200) {
    score += 2;
  } else if (pao2_fio2_ratio >= 100 && on_ventilation) {
    score += 3;
  } else if (pao2_fio2_ratio < 100 && on_ventilation) {
    score += 4;
  } else if (!on_ventilation) {
    score += 2;
  }

  score += platelets;
  score += bilirubin;
  score += glascow_coma_scale;
  score += map_or_vasoactive;
  score += creatinine;

  return {
    sofa_score: {
      prefix: 'SOFA Score',
      value: score,
    },
  };
};

registerCalculator('sofa-score', SOFACalculator, 'SOFA Score');
