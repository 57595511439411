// DO NOT MODIFY THIS FILE. IT WAS GENERATED BY GPT-4. ANY CHANGES WILL BE OVERWRITTEN.
import { registerCalculator } from '../registry';
import { CalculatorOutputValue, CalculatorOutput } from '../types';

export const AnionGapCalculator = (values: {
  sodium?: number | null;
  chloride?: number | null;
  bicarbonate?: number | null;
  albumin?: number | null;
}): CalculatorOutput => {
  const { sodium, chloride, bicarbonate, albumin } = values;

  if (sodium == null || chloride == null || bicarbonate == null) {
    return null;
  }

  const anion_gap = sodium - (chloride + bicarbonate);
  const rounded_anion_gap = Math.round(anion_gap * 100) / 100;
  const to_return: CalculatorOutput = {
    anion_gap: {
      prefix: 'Anion Gap',
      value: rounded_anion_gap,
      suffix: 'mEq/L',
    },
  };

  if (albumin != null) {
    const corrected_anion_gap = anion_gap + 2.5 * (4 - albumin);
    const rounded_corrected_anion_gap =
      Math.round(corrected_anion_gap * 100) / 100;
    to_return['corrected_anion_gap'] = {
      prefix: 'Corrected Anion Gap',
      value: rounded_corrected_anion_gap,
      suffix: 'mEq/L',
    };
  }

  return to_return;
};

registerCalculator('anion-gap', AnionGapCalculator, 'Anion Gap');
