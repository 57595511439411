// DO NOT MODIFY THIS FILE. IT WAS GENERATED BY GPT-4. ANY CHANGES WILL BE OVERWRITTEN.
import { registerCalculator } from '../registry';
import { CalculatorOutputValue, CalculatorOutput } from '../types';

export const BMIBSACalculator = (values: {
  weight?: number | null;
  height?: number | null;
  target_bmi?: number | null;
}): CalculatorOutput => {
  const { weight, height, target_bmi } = values;

  if (weight == null || height == null) {
    return null;
  }

  const bmi = weight / (height / 100) ** 2;
  const bsa = Math.sqrt((height * weight) / 3600);

  const rounded_bmi = Math.round(bmi * 100) / 100;
  const rounded_bsa = Math.round(bsa * 100) / 100;

  const to_return: CalculatorOutput = {
    bmi: {
      prefix: 'BMI',
      value: rounded_bmi,
      suffix: 'kg/m²',
    },
    bsa: {
      prefix: 'BSA',
      value: rounded_bsa,
      suffix: 'm²',
    },
  };

  if (target_bmi != null) {
    const target_weight = target_bmi * (height / 100) ** 2;
    const rounded_weight_kg = Math.round(target_weight * 100) / 100;
    const rounded_weight_lbs = Math.round(target_weight * 2.20462 * 100) / 100;

    to_return['target_weight_lbs'] = {
      prefix: 'Target Weight',
      value: rounded_weight_lbs,
      suffix: 'lbs',
    };
    to_return['target_weight_kg'] = {
      prefix: 'Target Weight',
      value: rounded_weight_kg,
      suffix: 'kg',
    };
  }

  return to_return;
};

registerCalculator('bmi-bsa', BMIBSACalculator, 'BMI and BSA');
