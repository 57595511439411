import type { ArticleData, ArticleSpan, Citation } from './types';
import { findCitationIndex } from './citations';
import { TableComponent } from './typewriter/types';
import {
  splitByBracket,
  expandStringToList,
} from './content/article_components/ArticleTable';
import { endWithPeriod } from './utils';
import * as Sentry from '@sentry/browser';

/**
 * Paste richly formatted text.
 * https://stackoverflow.com/questions/23934656/how-can-i-copy-rich-text-contents-to-the-clipboard-with-javascript/77305170#77305170
 *
 * @param {string} rich - the text formatted as HTML
 * @param {string} plain - a plain text fallback
 */
export async function pasteRich(rich: string, plain: string) {
  if (typeof ClipboardItem !== 'undefined') {
    // Shiny new Clipboard API, not fully supported in Firefox.
    // https://developer.mozilla.org/en-US/docs/Web/API/Clipboard_API#browser_compatibility
    const html = new Blob([rich], { type: 'text/html' });
    const text = new Blob([plain], { type: 'text/plain' });
    const data = new ClipboardItem({ 'text/html': html, 'text/plain': text });
    await navigator.clipboard.write([data]);
  } else {
    // Fallback using the deprecated `document.execCommand`.
    // https://developer.mozilla.org/en-US/docs/Web/API/Document/execCommand#browser_compatibility
    const cb = (e: ClipboardEvent) => {
      e.clipboardData?.setData('text/html', rich);
      e.clipboardData?.setData('text/plain', plain);
      e.preventDefault();
    };
    document.addEventListener('copy', cb);
    document.execCommand('copy');
    document.removeEventListener('copy', cb);
  }
}

const TABLE_COMPONENT_PREFIX = 'REACTCOMPONENT!:!Table!:!';

/**
 * Get the rich text html for a given citation based on its index.
 * This should return a link that shows [ref number] but links to the article page
 *
 * @param references
 * @param index
 * @returns
 */
function getCitationRichText(references: Citation[], index: number): string {
  const currentReference = references[index];
  if (!currentReference) {
    // This reference wasn't found or the index is invalid
    return '[]';
  }
  if (typeof currentReference === 'string') {
    return '[' + currentReference + ']';
  } else {
    return (
      "<a href='" +
      currentReference.metadata.citation_detail.href +
      "'>[" +
      (index + 1) +
      ']</a>'
    );
  }
}

const citationRegex = /\[\d+(?:-\d+)?(?:, \d+(?:-\d+)?)*\]/g;

/**
 * Replace citations that are already embedded in the article text, as opposed to added programmatically.
 * This primarily affects tables at the moment.
 *
 * @param text
 * @param references
 * @returns
 */
function replaceInTextCitations(text: string, references: Citation[]): string {
  const splitText: string[] = splitByBracket(text);
  let alteredText: string = '';
  splitText.forEach((term) => {
    alteredText +=
      term?.match(citationRegex)?.map(
        (match: string) =>
          `${expandStringToList(match)
            .map((index: number) => {
              return getCitationRichText(references, index - 1);
            })
            .join(', ')}`
      ) ?? term;
  });
  return alteredText;
}

/**
 * Generate the html rich text for a table.
 *
 * @param tableText
 * @param references
 * @returns
 */
function generateTableRichText(
  tableText: string,
  references: Citation[]
): string {
  const alteredTableText = replaceInTextCitations(tableText, references);
  const tableData: TableComponent = JSON.parse(
    alteredTableText.substring(TABLE_COMPONENT_PREFIX.length)
  );
  if (tableData.table_data.length === 0) {
    return '';
  }

  let tableString = '<table>';

  // Generate headers for column names
  const columnNames: string[] = Object.keys(tableData.table_data[0]);
  let headerString: string = '<tr>';
  columnNames.forEach((element) => {
    headerString += '<th>' + element + '</th>';
  });
  headerString += '</tr>';
  tableString += headerString;

  // Generate rows based on data
  tableData.table_data.forEach((rowData) => {
    let rowString: string = '<tr>';
    columnNames.forEach((element) => {
      rowString += '<td>';
      if (element in rowData) {
        rowString += rowData[element];
      }
      rowString += '</td>';
    });
    rowString += '</tr>';
    tableString += rowString;
  });

  tableString += '</table>';
  return tableString;
}

/**
 * Generates the rich text corresponding to an article span.
 *
 * @param span
 * @param references
 * @returns
 */
function getArticleSpanRichText(
  span: ArticleSpan,
  references: Citation[]
): string {
  if (span.text.startsWith(TABLE_COMPONENT_PREFIX)) {
    return generateTableRichText(span.text, references);
  } else {
    let spanText: string = span.text;
    const seenCitations: Set<number> = new Set<number>();
    span.citations.forEach((citation) => {
      const citationIndex: number = findCitationIndex(references, citation);
      if (!seenCitations.has(citationIndex)) {
        seenCitations.add(citationIndex);
        spanText += getCitationRichText(references, citationIndex);
      }
    });
    return spanText;
  }
}

/**
 * Generates the rich text for the references section of an article
 *
 * @param references
 * @returns
 */
function getReferencesRichText(references: Citation[]): string {
  let referencesText: string = '<h3>References</h3>';
  referencesText += '<ol type="1">';
  references.forEach((citation) => {
    referencesText += '<li>';
    if (typeof citation === 'string') {
      referencesText += citation;
    } else {
      referencesText += endWithPeriod(
        "<a href='" +
          citation.metadata.citation_detail.href +
          "'>" +
          citation.metadata.citation_detail.title +
          '</a>'
      );
      if (citation.metadata.citation_detail.authors_string) {
        referencesText +=
          ' ' + endWithPeriod(citation.metadata.citation_detail.authors_string);
      }
      if (citation.metadata.citation_detail.publication_info_string) {
        referencesText +=
          ' ' +
          endWithPeriod(
            citation.metadata.citation_detail.publication_info_string
          );
      }
    }
    referencesText += '</li>';
  });
  referencesText += '</ol>';
  return referencesText;
}

/**
 * Generates the rich text for an entire article, including the contained text, tables, and references.
 *
 * @param article
 * @param references
 * @returns
 */
export default function getArticleRichText(
  article: ArticleData,
  references: Citation[]
): string {
  let totalText: string = '';
  article.articlesection_set.forEach((section) => {
    // Section titles: show when present, otherwise separate sections with
    // an extra line break.
    if (section.section_title) {
      totalText += '<h3>' + section.section_title + '</h3><br>';
    } else if (totalText.length > 0) {
      totalText += '<br>';
    }

    section.articleparagraph_set.forEach((paragraph) => {
      paragraph.articlespan_set.forEach((span) => {
        totalText += getArticleSpanRichText(span, references);
      });
      totalText += '<br><br>';
    });
  });

  if (references.length > 0) {
    totalText += getReferencesRichText(references);
  }

  if (totalText.includes('[]')) {
    Sentry.withScope((scope) => {
      scope.setTag('xyla.error_source', 'tables');
      scope.setLevel('warning');
      Sentry.captureMessage(
        '[Tables] Warning: Table did not render a complete references section.'
      );
    });
  }
  return totalText;
}
