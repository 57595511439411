// DO NOT MODIFY THIS FILE. IT WAS GENERATED BY GPT-4. ANY CHANGES WILL BE OVERWRITTEN.
import { registerCalculator } from '../registry';
import { CalculatorOutputValue, CalculatorOutput } from '../types';

export const CHA2DS2VAScCalculator = (values: {
  age?: '<65' | '65-74' | '75+';
  sex?: 'male' | 'female';
  hypertension?: boolean;
  diabetes_mellitus?: boolean;
  stroke_or_TIA_or_thromboembolism?: boolean;
  vascular_disease?: boolean;
  congestive_heart_failure?: boolean;
}): CalculatorOutput => {
  const {
    age,
    sex,
    hypertension = false,
    diabetes_mellitus = false,
    stroke_or_TIA_or_thromboembolism = false,
    vascular_disease = false,
    congestive_heart_failure = false,
  } = values;

  if (
    age == null ||
    sex == null ||
    hypertension == null ||
    diabetes_mellitus == null ||
    stroke_or_TIA_or_thromboembolism == null ||
    vascular_disease == null ||
    congestive_heart_failure == null
  ) {
    return null;
  }

  let score = 0;

  if (age === '65-74') {
    score += 1;
  } else if (age === '75+') {
    score += 2;
  }

  if (sex === 'female') {
    score += 1;
  }

  score += Number(hypertension);
  score += Number(diabetes_mellitus);
  score += 2 * Number(stroke_or_TIA_or_thromboembolism);
  score += Number(vascular_disease);
  score += Number(congestive_heart_failure);

  return {
    chadsvasc: {
      prefix: 'CHA₂DS₂-VASc Score',
      value: score,
    },
  };
};

registerCalculator('chadsvasc', CHA2DS2VAScCalculator, 'CHA₂DS₂-VASc Score');
