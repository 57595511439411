// DO NOT MODIFY THIS FILE. IT WAS GENERATED BY GPT-4. ANY CHANGES WILL BE OVERWRITTEN.
import { registerCalculator } from '../registry';
import { CalculatorOutputValue, CalculatorOutput } from '../types';

export const GuptaPerioperativeRiskCalculator = (values: {
  age?: number | null;
  functional_status?:
    | 'independent'
    | 'partially_dependent'
    | 'totally_dependent'
    | null;
  asa_class?: number | null;
  type_of_surgery?:
    | 'anorectal'
    | 'aortic'
    | 'bariatric'
    | 'brain'
    | 'breast'
    | 'cardiac'
    | 'ent'
    | 'foregut_hepato_pancreatobiliary'
    | 'gbaas'
    | 'hernia'
    | 'intestinal'
    | 'neck'
    | 'obstetric_gynecologic'
    | 'orthopedic'
    | 'other_abdomen'
    | 'peripheral_vascular'
    | 'skin'
    | 'spine'
    | 'thoracic'
    | 'vein'
    | 'urology'
    | null;
  creatinine?: 'normal' | 'elevated' | 'unknown' | null;
}): CalculatorOutput => {
  const {
    age,
    functional_status = 'independent',
    asa_class = 1,
    type_of_surgery,
    creatinine = 'normal',
  } = values;

  if (
    age == null ||
    functional_status == null ||
    asa_class == null ||
    type_of_surgery == null ||
    creatinine == null
  ) {
    return null;
  }

  let x = -5.25;

  if (functional_status === 'totally_dependent') {
    x += 1.03;
  } else if (functional_status === 'partially_dependent') {
    x += 0.65;
  }

  if (asa_class === 1) {
    x -= 5.17;
  } else if (asa_class === 2) {
    x -= 3.29;
  } else if (asa_class === 3) {
    x -= 1.92;
  } else if (asa_class === 4) {
    x -= 0.95;
  } else if (asa_class === 5) {
    // No change for asa_class 5
  }

  if (creatinine === 'elevated') {
    x += 0.61;
  } else if (creatinine === 'unknown') {
    x -= 0.1;
  }

  // Age
  x += 0.02 * (age - 1);

  // Type of surgery
  const surgery_to_bias: { [key: string]: number } = {
    anorectal: -0.16,
    aortic: 1.6,
    bariatric: -0.25,
    brain: 1.4,
    breast: -1.61,
    cardiac: 1.01,
    ent: 0.71,
    foregut_hepato_pancreatobiliary: 1.39,
    gbaas: 0.59,
    hernia: 0.0,
    intestinal: 1.14,
    neck: 0.18,
    obstetric_gynecologic: 0.76,
    orthopedic: 0.8,
    other_abdomen: 1.13,
    peripheral_vascular: 0.86,
    skin: 0.54,
    spine: 0.21,
    thoracic: 0.4,
    vein: -1.09,
    urology: -0.26,
  };

  x += surgery_to_bias[type_of_surgery];

  // Cardiac risk, % = ex / (1 + ex)
  // Where x = −5.25 + sum of the values of the selected variables.
  const cardiac_risk = Math.exp(x) / (1 + Math.exp(x));

  return {
    cardiac_risk: {
      prefix: 'Cardiac Risk',
      value: Math.round(cardiac_risk * 100 * 100) / 100, // rounding to 2 decimal places
      suffix: '%',
    },
  };
};

registerCalculator(
  'gupta_perioperative_risk',
  GuptaPerioperativeRiskCalculator,
  'Gupta Perioperative Risk for Myocardial Infarction or Cardiac Arrest (MICA)'
);
