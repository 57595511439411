// DO NOT MODIFY THIS FILE. IT WAS GENERATED BY GPT-4. ANY CHANGES WILL BE OVERWRITTEN.
import { registerCalculator } from '../registry';
import { CalculatorOutputValue, CalculatorOutput } from '../types';

export const FreeWaterDeficitCalculator = (values: {
  age?: 'child' | 'adult' | 'elderly' | null;
  sex?: 'male' | 'female' | null;
  weight?: number | null;
  serum_sodium?: number | null;
  desired_sodium?: number | null;
}): CalculatorOutput => {
  const { age, sex, weight, serum_sodium, desired_sodium } = values;

  if (
    age == null ||
    sex == null ||
    weight == null ||
    serum_sodium == null ||
    desired_sodium == null
  ) {
    return null;
  }

  let tbw: number | null = null;

  if (age === 'child') {
    tbw = 0.6 * weight;
  } else if (age === 'adult' && sex === 'male') {
    tbw = 0.6 * weight;
  } else if (age === 'adult' && sex === 'female') {
    tbw = 0.5 * weight;
  } else if (age === 'elderly' && sex === 'male') {
    tbw = 0.5 * weight;
  } else if (age === 'elderly' && sex === 'female') {
    tbw = 0.45 * weight;
  } else {
    return null;
  }

  const fwd = (tbw * (serum_sodium - desired_sodium)) / desired_sodium;
  const rounded_fwd = Math.round(fwd * 100) / 100;

  return {
    free_water_deficit: {
      prefix: 'Free Water Deficit',
      value: rounded_fwd,
      suffix: 'L',
    },
  };
};

registerCalculator(
  'free-water-deficit',
  FreeWaterDeficitCalculator,
  'Free Water Deficit Calculator'
);
