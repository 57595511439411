// DO NOT MODIFY THIS FILE. IT WAS GENERATED BY GPT-4. ANY CHANGES WILL BE OVERWRITTEN.
import { registerCalculator } from '../registry';
import { CalculatorOutputValue, CalculatorOutput } from '../types';

export const MELDCalculator = (values: {
  age?: number | null;
  sex?: 'male' | 'female' | null;
  serum_creatinine?: number | null;
  bilirubin?: number | null;
  inr?: number | null;
  serum_sodium?: number | null;
  albumin?: number | null;
  dialysis?: boolean | null;
}): CalculatorOutput => {
  const {
    age,
    sex,
    serum_creatinine,
    bilirubin,
    inr,
    serum_sodium,
    albumin,
    dialysis = false,
  } = values;

  const _meld_score = (
    creatinine?: number | null,
    bilirubin?: number | null,
    inr?: number | null,
    dialysis?: boolean | null
  ): CalculatorOutput => {
    if (
      creatinine == null ||
      bilirubin == null ||
      inr == null ||
      dialysis == null
    ) {
      return null;
    }

    creatinine = Math.max(creatinine, 1.0);
    bilirubin = Math.max(bilirubin, 1.0);
    inr = Math.max(inr, 1.0);
    creatinine = Math.min(creatinine, 4.0);

    if (dialysis) {
      creatinine = 4.0;
    }

    const meld_score = Math.max(
      6,
      Math.round(
        9.57 * Math.log(creatinine) +
          3.78 * Math.log(bilirubin) +
          11.2 * Math.log(inr) +
          6.43
      )
    );

    return { meld: { prefix: 'MELD', value: meld_score } };
  };

  const _meldna_score = (
    creatinine?: number | null,
    bilirubin?: number | null,
    inr?: number | null,
    sodium?: number | null,
    dialysis?: boolean | null
  ): CalculatorOutput => {
    if (
      creatinine == null ||
      bilirubin == null ||
      inr == null ||
      sodium == null ||
      dialysis == null
    ) {
      return null;
    }

    const meld_score = _meld_score(creatinine, bilirubin, inr, dialysis);
    if (!meld_score) {
      return null;
    }

    const meld_value = meld_score['meld'].value as number;
    sodium = Math.max(Math.min(sodium, 137), 125);
    const meldna_score = Math.max(
      6,
      Math.round(
        meld_value + 1.32 * (137 - sodium) - 0.033 * meld_value * (137 - sodium)
      )
    );

    return { meld_na: { prefix: 'MELD-Na', value: meldna_score } };
  };

  const _meld_3_0_score = (
    age?: number | null,
    sex?: 'male' | 'female' | null,
    creatinine?: number | null,
    bilirubin?: number | null,
    inr?: number | null,
    sodium?: number | null,
    albumin?: number | null,
    dialysis?: boolean | null
  ): CalculatorOutput => {
    if (
      age == null ||
      sex == null ||
      creatinine == null ||
      bilirubin == null ||
      inr == null ||
      sodium == null ||
      dialysis == null
    ) {
      return null;
    }

    const apply_meld_3_0_formula = (score: number): number => {
      return Math.pow(0.946, Math.exp(0.17698 * score - 3.56)) * 100;
    };

    creatinine = Math.max(creatinine, 1.0);
    bilirubin = Math.max(bilirubin, 1.0);
    inr = Math.max(inr, 1.0);
    sodium = Math.max(Math.min(sodium, 137), 125);
    creatinine = Math.min(creatinine, 3.0);

    if (dialysis) {
      creatinine = 3.0;
    }

    if (albumin == null && age >= 18) {
      const meld_score = Math.round(
        (sex === 'female' ? 1.4 : 1.0) +
          4.85 * Math.log(bilirubin) +
          0.88 * (137 - sodium) -
          0.25 * (137 - sodium) * Math.log(bilirubin) +
          9.66 * Math.log(inr) +
          10.47 * Math.log(creatinine) +
          6
      );

      const min_meld_score = Math.max(6, meld_score);
      const survival_probability = apply_meld_3_0_formula(min_meld_score);
      const rounded_survival_probability =
        Math.round(survival_probability * 100) / 100;

      return {
        meld_3_no_albumin_pts: {
          prefix: 'MELD 3.0, No Albumin',
          value: min_meld_score,
        },
        meld_3_no_albumin_survival: {
          prefix: 'MELD 3.0 (No Albumin) Based 90-day survival probability',
          value: rounded_survival_probability,
          suffix: '%',
        },
      };
    } else if (albumin == null) {
      return null;
    }

    albumin = Math.max(Math.min(albumin, 3.5), 1.5);

    if (age < 12) {
      return null;
    } else if (age >= 12 && age < 18) {
      const meld_score = Math.round(
        4.56 * Math.log(bilirubin) +
          0.82 * (137 - sodium) -
          0.24 * (137 - sodium) * Math.log(bilirubin) +
          9.09 * Math.log(inr) +
          11.14 * Math.log(creatinine) +
          1.85 * (3.5 - albumin) -
          1.83 * (3.5 - albumin) * Math.log(creatinine) +
          7.33
      );

      const min_meld_score = Math.max(6, meld_score);
      const survival_probability = apply_meld_3_0_formula(min_meld_score);
      const rounded_survival_probability =
        Math.round(survival_probability * 100) / 100;

      return {
        meld_3_12_17_pts: {
          prefix: 'MELD 3.0 (12-17 years old)',
          value: min_meld_score,
        },
        meld_3_12_17_survival: {
          prefix:
            'MELD 3.0 (12-17 years old) Based 90-day survival probability',
          value: rounded_survival_probability,
          suffix: '%',
        },
      };
    } else {
      const meld_score = Math.round(
        (sex === 'female' ? 1.33 : 1) * 4.56 * Math.log(bilirubin) +
          0.82 * (137 - sodium) -
          0.24 * (137 - sodium) * Math.log(bilirubin) +
          9.09 * Math.log(inr) +
          11.14 * Math.log(creatinine) +
          1.85 * (3.5 - albumin) -
          1.83 * (3.5 - albumin) * Math.log(creatinine) +
          6
      );

      const min_meld_score = Math.max(6, meld_score);
      const survival_probability = apply_meld_3_0_formula(min_meld_score);
      const rounded_survival_probability =
        Math.round(survival_probability * 100) / 100;

      return {
        meld_3_18_pts: { prefix: 'MELD 3.0 (18+)', value: min_meld_score },
        meld_3_18_survival: {
          prefix: 'MELD 3.0 (18+) Based 90-day survival probability',
          value: rounded_survival_probability,
          suffix: '%',
        },
      };
    }
  };

  const meld_score = _meld_score(serum_creatinine, bilirubin, inr, dialysis);
  const meldna_score = _meldna_score(
    serum_creatinine,
    bilirubin,
    inr,
    serum_sodium,
    dialysis
  );
  const meld_3_0_score = _meld_3_0_score(
    age,
    sex,
    serum_creatinine,
    bilirubin,
    inr,
    serum_sodium,
    albumin,
    dialysis
  );

  const to_return: CalculatorOutput = {};
  if (meld_score) {
    Object.assign(to_return, meld_score);
  }

  if (meldna_score) {
    Object.assign(to_return, meldna_score);
  }

  if (meld_3_0_score) {
    Object.assign(to_return, meld_3_0_score);
  }

  return Object.keys(to_return).length > 0 ? to_return : null;
};

registerCalculator('meld', MELDCalculator, 'MELD Score');
